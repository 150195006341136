.pt-50 {
  padding-top: 50px; }
.pb-50 {
  padding-bottom: 50px; }
.pt-80 {
  padding-top: 80px;
  @include media(767px) {
    padding-top: 70px; }
  @include media(575px) {
    padding-top: 50px; } }
.pb-80 {
  padding-bottom: 80px;
  @include media(767px) {
    padding-bottom: 70px; }
  @include media(575px) {
    padding-bottom: 50px; } }
.pt-100 {
  padding-top: 100px;
  @include media(991px) {
    padding-top: 80px; }
  @include media(767px) {
    padding-top: 70px; }
  @include media(575px) {
    padding-top: 50px; } }
.pb-100 {
  padding-bottom: 100px;
  @include media(991px) {
    padding-bottom: 80px; }
  @include media(767px) {
    padding-bottom: 70px; }
  @include media(575px) {
    padding-bottom: 50px; } }
