/* why choose us section css start */
.choose-us-section {
  @extend %position-relative;
  @extend %z-index-p;
  &::after {
    @extend %position-absolute;
    content: '';
    top: 0;
    right: 0;
    width: 50%;
    height: calc(100% - 100px);
    background-color: rgba($base-color, 0.05);
    @extend %z-index-c; } }
.choose-right-content {
  padding-left: rem(50px);
  @include media(991px) {
    padding-left: 0; } }

.overview-area {
  @extend %d-flex;
  margin-top: rem(50px);
  .left {
    width: 50%;
    @include media(575px) {
      width: 100%;
      height: 300px; }
    img {
      @extend %w-100;
      @extend %h-100;
      @extend %obj-fit; } }
  .right {
    @extend %position-relative;
    width: 50%;
    @extend %base-color;
    padding: rem(50px);
    @extend %d-flex;
    @extend %align-center;
    @extend %justify-center;
    @include media(767px) {
      padding: rem(20px); }
    @include media(575px) {
      width: 100%; }
    .map-el {
      width: 75%;
      height: 300px;
      @extend %position-absolute;
      @extend %d-flex;
      @extend %align-center;
      @extend %justify-center;
      top: 50%;
      left: 50%;
      opacity: 0.5;
      @include transform(translate(-50%, -50%)); } } }

.overview-list {
  @extend %d-flex;
  @extend %overflow-hidden;
  @extend %position-relative;
  &::after {
    @extend %position-absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 1px;
    height: 120px;
    margin-top: -60px;
    background-color: rgba(#000, 0.15); }
  &::before {
    @extend %position-absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 120px;
    height: 1px;
    margin-left: -60px;
    background-color: rgba(#000, 0.15); } }
.overview-item {
  @extend %text-center;
  width: 50%;
  padding: rem(20px);
  .amount {
    font-family: $heading-font;
    font-weight: 700;
    @extend %text-h;
    font-size: rem(48px);
    line-height: 1;
    @include media(991px) {
      font-size: rem(36px); }
    @include media(767px) {
      font-size: rem(28px); } }
  .caption {
    font-weight: 500;
    @extend %text-h;
    margin-top: rem(10px);
    @include media(767px) {
      font-size: rem(14px); } } }
/* why choose us section css end */
