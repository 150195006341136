/* testimonial section css start */
.testimonial-section {
  @extend %d-flex;
  .left {
    width: 60%; }
  .right {
    width: 40%;
    padding-right: 15%;
    @include media(1650px) {
      padding-right: 8%; }
    @include media(1399px) {
      padding-right: rem(40px); }
    @include media(767px) {
      width: 100%;
      padding-left: rem(30px);
      padding-right: rem(30px); }
    @include media(575px) {
      padding-left: rem(15px);
      padding-right: rem(15px); }
    .testimonial-header {
      padding-left: rem(40px);
      margin-bottom: rem(40px);
      @include media(767px) {
        text-align: center;
        padding: 0 rem(20px); } } } }
.testimonial-slider {
  padding: rem(70px) rem(50px) rem(50px) rem(50px);
  @extend %base-color;
  margin-left: -100px;
  @extend %position-relative;
  @include media(991px) {
    padding: rem(70px) rem(30px) rem(30px) rem(30px); }
  @include media(767px) {
    margin-left: 0; }
  &::after {
    @extend %position-absolute;
    top: 15px;
    left: 43px;
    content: "\f10d";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    @extend %text-h;
    font-size: 56px;
    line-height: 1;
    @include media(991px) {
      left: 22px; } } }

.testimonial-user {
  margin-top: rem(20px);
  @extend %d-flex;
  @extend %align-center;
  .thumb {
    width: 75px;
    height: 75px;
    @extend %bs-50;
    @extend %overflow-hidden;
    border: 2px solid $base-color-two;
    img {
      @extend %w-100;
      @extend %h-100;
      @extend %obj-fit; } }
  .content {
    padding-left: rem(20px);
    width: calc(100% - 75px); } }
/* testimonial section css end */
