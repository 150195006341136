/* reset css start */
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Playfair+Display:wght@400;500;600;700&family=Poppins:wght@400;500&display=swap");
html {
  scroll-behavior: smooth; }

body {
  font-family: "Poppins", sans-serif;
  color: #666666;
  font-size: 0.9375rem;
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  line-height: 1.7;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  overflow-x: hidden;
  counter-reset: section; }
  body::-webkit-scrollbar {
    width: 8px; }
  body::-webkit-scrollbar-track {
    background: #f2f2f2; }
  body::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 2px solid #f2f2f2; }

@-webkit-keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

img {
  max-width: 100%;
  height: auto;
  user-select: none; }

select {
  cursor: pointer; }

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none; }

button {
  cursor: pointer; }

*:focus {
  outline: none; }

button {
  border: none; }

button:focus {
  outline: none; }

span {
  display: inline-block; }

a:hover {
  color: #f1b85c; }

hr {
  background-color: #dadce0;
  opacity: 0.65; }

.page-scroll-bar {
  position: fixed;
  left: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 2px;
  border: none;
  background: transparent;
  z-index: 99; }
  .page-scroll-bar::-webkit-progress-bar {
    background: transparent; }
  .page-scroll-bar::-webkit-progress-value {
    background: linear-gradient(to left, #db38b5, #01b3e3, #25ce7b, #fdc741, #ff6b01, #f1b85c);
    background-attachment: fixed; }
  .page-scroll-bar::-moz-progress-bar {
    background: linear-gradient(to left, #db38b5, #01b3e3, #25ce7b, #fdc741, #ff6b01, #f1b85c);
    background-attachment: fixed; }

/* reset css end */
/* global css strat */
.text--primary {
  color: #7367f0 !important; }

.text--secondary {
  color: #868e96 !important; }

.text--success {
  color: #28c76f !important; }

.text--danger {
  color: #ea5455 !important; }

.text--warning {
  color: #ff9f43 !important; }

.text--info {
  color: #1e9ff2 !important; }

.text--dark {
  color: #082032 !important; }

.text--muted {
  color: #a0a0a0 !important; }

.text--base {
  color: #f1b85c !important; }

.text--dark {
  color: #082032 !important; }

.text--h {
  color: #082032 !important; }

.text--p {
  color: #666666 !important; }

/* background color css start */
.bg--primary {
  background-color: #7367f0 !important; }

.bg--secondary {
  background-color: #868e96 !important; }

.bg--success {
  background-color: #28c76f !important; }

.bg--danger {
  background-color: #ea5455 !important; }

.bg--warning {
  background-color: #ff9f43 !important; }

.bg--info {
  background-color: #1e9ff2 !important; }

.bg--dark {
  background-color: #082032 !important; }

.bg--light {
  background-color: #bcc7da !important; }

.bg--base {
  background-color: #f1b85c !important; }

/* background color css end */
.bg_img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.bg-attachment-fixed {
  background-attachment: fixed; }

.section--bg {
  background-color: #F8F9FA !important; }

.section--bg {
  background-color: #F8F9FA !important; }

.section--bg2 {
  background-color: #1a2a4a; }

.bg-base--light {
  background-color: rgba(241, 184, 92, 0.05) !important; }

.top--border-base {
  border-top: 2px solid #f1b85c; }

.alert--dark {
  background-color: #505C66; }

.info-message-alert .icon {
  width: 40px;
  font-size: 1.25rem; }

.info-message-alert .content {
  width: calc(100% - 40px); }

.slick-arrow {
  cursor: pointer; }

.z-index-2 {
  z-index: 2; }

.main-wrapper {
  position: relative; }

.section-header {
  margin-bottom: 2.8125rem;
  margin-top: -8px; }
  @media (max-width: 767px) {
    .section-header {
      margin-bottom: 2.1875rem; } }
  @media (max-width: 575px) {
    .section-header {
      margin-bottom: 1.875rem; } }
  @media (max-width: 767px) {
    .section-header p {
      font-size: 0.9375rem; } }

.section-title {
  font-size: 3rem;
  font-weight: 700; }
  @media (max-width: 1199px) {
    .section-title {
      font-size: 2.25rem; } }
  @media (max-width: 767px) {
    .section-title {
      font-size: 1.75rem; } }
  @media (max-width: 420px) {
    .section-title {
      font-size: 1.5rem; } }

.section-top-title {
  font-weight: 500;
  letter-spacing: 3px; }

a.text-white:hover {
  color: #f1b85c !important; }

.text--link {
  text-decoration: underline; }
  .text--link:hover {
    text-decoration: underline; }

.has--link {
  position: relative; }
  .has--link .item--link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.object-fit--cover {
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center; }

.dark--overlay::after {
  content: '';
  top: 0;
  left: 0;
  opacity: 0.75; }

/* back to top css start */
.progress-wrap {
  position: fixed;
  right: 10px;
  bottom: 10px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(241, 184, 92, 0.2);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background: #fff; }
  .progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  .progress-wrap::after {
    position: absolute;
    content: "\f30c";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    text-align: center;
    line-height: 46px;
    font-size: 20px;
    color: #f1b85c;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; }
  .progress-wrap svg path {
    fill: none; }
  .progress-wrap svg.progress-circle path {
    stroke: #f1b85c;
    stroke-width: 4;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; }

/* back to top css end */
.preloader {
  position: fixed;
  inset: 0;
  z-index: 9999999; }
  .preloader__circle {
    width: 75px;
    height: 75px; }
    .preloader__circle::after {
      content: '';
      border: 3px solid rgba(255, 255, 255, 0.15);
      border-right: none;
      border-bottom: none;
      animation: spin 1.5s infinite linear; }
    .preloader__circle i {
      font-size: 2.125rem; }

/* global css end */
.pt-50 {
  padding-top: 50px; }

.pb-50 {
  padding-bottom: 50px; }

.pt-80 {
  padding-top: 80px; }
  @media (max-width: 767px) {
    .pt-80 {
      padding-top: 70px; } }
  @media (max-width: 575px) {
    .pt-80 {
      padding-top: 50px; } }

.pb-80 {
  padding-bottom: 80px; }
  @media (max-width: 767px) {
    .pb-80 {
      padding-bottom: 70px; } }
  @media (max-width: 575px) {
    .pb-80 {
      padding-bottom: 50px; } }

.pt-100 {
  padding-top: 100px; }
  @media (max-width: 991px) {
    .pt-100 {
      padding-top: 80px; } }
  @media (max-width: 767px) {
    .pt-100 {
      padding-top: 70px; } }
  @media (max-width: 575px) {
    .pt-100 {
      padding-top: 50px; } }

.pb-100 {
  padding-bottom: 100px; }
  @media (max-width: 991px) {
    .pb-100 {
      padding-bottom: 80px; } }
  @media (max-width: 767px) {
    .pb-100 {
      padding-bottom: 70px; } }
  @media (max-width: 575px) {
    .pb-100 {
      padding-bottom: 50px; } }

.dark--overlay, .preloader__circle, .cmn-btn, .cmn-btn-2, .read-more-btn, .custom-icon-field, .faq-single__header, .footer, .footer-widget__title, .action-widget__body, .hero, .inner-hero, .about-thumb-area, .campaign-item .thumb, .campaign-item .bottom .single-amount, .progressbar, .choose-us-section, .overview-area .right, .overview-list, .volunteer-item .thumb, .testimonial-slider, .contact-section, .qr-code-form, .campaign-details-amount-list .single-amount-list, .gallery-item, .d-widget.style--two {
  position: relative; }

.dark--overlay::after, .preloader__circle::after, .cmn-btn::after, .cmn-btn-2::after, .read-more-btn::before, .custom-icon-field i, .faq-single__header::after, .header .main-menu > li::after, .footer::after, .footer .bg-el, .footer-widget__title::after, .hero::before, .hero__top, .hero__left, .hero__right, .hero .hero-img-slider, .inner-hero::before, .about-thumb2, .about-thumb2 .video-btn, .about-thumb2 .video-btn::after, .campaign-section-layer, .campaign-section-layer::before, .campaign-item .bottom .single-amount::after, .progressbar .bar, .progressbar .label, .progressbar .label:before, .campaign-slider .slick-arrow, .choose-us-section::after, .overview-area .right .map-el, .overview-list::after, .overview-list::before, .volunteer-slider .slick-arrow, .testimonial-slider::after, .contact-section::after, .qr-code-form__btn, .campaign-details-amount-list .single-amount-list::before, .campaign-details-amount-list .single-amount-list::after, .gallery-item .gallery-item-icon, .d-widget.style--two::after {
  position: absolute; }

.dark--overlay, .cmn-btn, .cmn-btn-2, .read-more-btn, .header .main-menu > li, .footer, .hero, .inner-hero, .about-thumb2 .video-btn, .campaign-slider .slick-arrow, .choose-us-section, .volunteer-slider .slick-arrow {
  z-index: 1; }

.dark--overlay::after, .cmn-btn::after, .cmn-btn-2::after, .read-more-btn::before, .header .main-menu > li::after, .footer::after, .footer .bg-el, .inner-hero::before, .about-thumb2 .video-btn::after, .campaign-section-layer, .campaign-section-layer::before, .choose-us-section::after {
  z-index: -1; }

body, .cmn-btn, .cmn-btn-2, .cmn-btn::after, .cmn-btn-2::after, .read-more-btn::before, .custom--checkbox label::before, .faq-single__header, .faq-single__header::after, .faq-single__title, .header .main-menu > li::after, .header .main-menu > li.menu_has_children > a::before, .header .main-menu > li .sub-menu, .header .main-menu > li .sub-menu li a, .header__search-btn i::before, .header-btn, .inline-menu li a, .social-links li a, .action-sidebar, .about-thumb2 .video-btn, .about-thumb2 .video-btn::after, .campaign-item, .post-item__thumb, .gallery-item .gallery-item-icon, .gallery-item .gallery-item-icon i, .d-widget.style--two {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

body::-webkit-scrollbar-thumb, .btn--base, .cmn-btn, .read-more-btn::before, .header .main-menu > li::after, .header-btn, .social-links li a:hover, .about-thumb2 .video-btn, .about-thumb2 .video-btn::after, .campaign-item__tag, .progressbar .bar, .progressbar .label, .overview-area .right, .testimonial-slider, .post-share li a:hover, .blog-sidebar .title::after, .profile-thumb .avatar-edit label, .campaign-details-content .custom--nav-tabs .nav-item .nav-link.active, .d-widget__icon {
  background-color: #f1b85c; }

.dark--overlay::after, .preloader, .custom--table thead, .faq-single.active .faq-single__header, .header.menu-fixed .header__bottom, .footer, .footer::after, .social-share a, .hero::before, .inner-hero::before, .about-thumb2 .video-btn:hover, .about-thumb2 .video-btn:hover::after, .campaign-section-layer::before, .d-widget {
  background-color: #1a2a4a; }

.t-company-thumb, .form-control:focus, .form--control, .form--control:focus, .select, .custom--card, .pagination .page-item .page-link, .header .main-menu > li .sub-menu, .event-details-widget, .campaign-item, .campaign-slider .slick-arrow, .volunteer-item, .volunteer-slider .slick-arrow, .social-links li a, .account-wrapper, .qr-code-wrapper {
  background-color: #fff; }

.section-top-title, .preloader__circle i, .read-more-btn, .header .site-logo.site-title, .header .main-menu > li a.active, .header .main-menu > li .sub-menu li a:hover, .header-btn:hover, .inline-menu li a:hover, .footer-widget__menu li a:hover, .footer-widget__contact-list li .icon, .hero__top-title, .campaign-item .bottom .single-amount .amount {
  color: #f1b85c; }

.alert--dark, [class*="btn--"]:not(.btn--link):not(.btn--light), .btn--dark, .btn--dark:hover, .btn--base, .btn--base:hover, .cmn-btn-2, .btn-outline--primary:hover, .btn-outline--secondary:hover, .btn-outline--success:hover, .btn-outline--danger:hover, .btn-outline--warning:hover, .btn-outline--info:hover, .btn-outline--light:hover, .btn-outline--dark:hover, .btn-outline--base:hover, .icon-btn, .icon-btn:hover, .custom--table thead th, .form--control.style--dark, .faq-single.active .faq-single__title, .faq-single.active .faq-single__header::after, .header__search-btn, .language-select, .footer .social-links li a, .social-share a, .hero__title, .about-thumb2 .video-btn:hover, .post-share li a:hover, .profile-thumb .avatar-edit label, .custom--file-upload::before, .gallery-item .gallery-item-icon, .d-widget__amount {
  color: #fff; }

.caption-list li .caption, .cmn-btn, .cmn-btn-2:hover, [data-label]::before, .form-control:focus, .form--control:focus, .select, .language-select option, .header-btn, .about-thumb2 .video-btn, .campaign-item__tag, .campaign-item .bottom .single-amount .caption, .progressbar .label, .campaign-slider .slick-arrow, .overview-item .amount, .overview-item .caption, .volunteer-slider .slick-arrow, .testimonial-slider::after, .social-links li a:hover, .campaign-details-content .custom--nav-tabs .nav-item .nav-link.active, .d-widget__icon {
  color: #082032; }

.custom--nav-tabs .nav-item .nav-link, #filter-domain-add-list a, .price-slider .range-amount, .contact-list li .content a, .social-links li a {
  color: #666666; }

.widget-organizer .thumb img, .hero .hero-img-slider .single-slide img, .about-thumb2 img, .founder .thumb img, .campaign-item .thumb img, .overview-area .left img, .volunteer-item .thumb img, .testimonial-user .thumb img, .post-item__thumb img, .blog-details-thumb img, .s-post__thumb img, .campaign-details-top .thumb img {
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center; }

.info-message-alert, .preloader, .btn-close, .social-links li a, .footer-widget__contact-list li, .widget-organizer, .event-wiget-info li, .social-share, .social-share a, .price-slider, .price-slider .amount__field, .about-thumb2 .video-btn, .founder, .campaign-item .bottom, .progressbar .label, .campaign-slider .slick-arrow, .overview-area, .overview-area .right, .overview-area .right .map-el, .overview-list, .volunteer-section, .volunteer-slider .slick-arrow, .testimonial-section, .testimonial-user, .contact-list li, .social-links, .profile-info-list li, .qr-code-copy-form, .support-upload-field, .single-file-upload, .campaign-details-amount-list, .campaign-details-amount-list .single-amount-list .caption, .gallery-item .gallery-item-icon, .d-widget.style--two {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.preloader, .preloader__circle, .btn-close, .social-links li a, .social-share a, .about-thumb2 .video-btn, .progressbar .label, .campaign-slider .slick-arrow, .overview-area .right, .overview-area .right .map-el, .volunteer-slider .slick-arrow, .gallery-item .gallery-item-icon, .d-widget__icon {
  justify-content: center; }

.info-message-alert, .preloader, .preloader__circle, .btn-close, .social-links li a, .footer-widget__contact-list li, .widget-organizer, .social-share, .social-share a, .price-slider .amount__field, .about-thumb2 .video-btn, .founder, .progressbar .label, .campaign-slider .slick-arrow, .overview-area .right, .overview-area .right .map-el, .volunteer-section, .volunteer-slider .slick-arrow, .testimonial-user, .contact-list li, .campaign-details-amount-list, .campaign-details-amount-list .single-amount-list .caption, .donate-form, .gallery-item .gallery-item-icon, .d-widget__icon, .d-widget.style--two {
  align-items: center; }

.preloader__circle, .donate-form, .d-widget__icon {
  display: inline-flex; }

.cmn-btn, .cmn-btn-2, .footer, .widget-organizer .thumb, .hero, .about-thumb2, .founder .thumb, .campaign-item .thumb, .progressbar .bar, .overview-list, .volunteer-section, .volunteer-item .thumb, .testimonial-user .thumb, .post-item__thumb, .blog-details-thumb, .campaign-details-top .thumb {
  overflow: hidden; }

.dark--overlay::after, .preloader__circle::after, .read-more-btn::before, .form--control, .select, .custom--nav-tabs .nav-item .nav-link, .header, .header .main-menu > li::after, .footer::after, .footer .bg-el, .footer .bg-el img, .widget-organizer .thumb img, .hero::before, .hero .hero-img-slider .single-slide img, .inner-hero::before, .about-thumb img, .campaign-section-layer, .campaign-section-layer::before, .campaign-item .thumb img, .progressbar, .overview-area .left img, .volunteer-item .thumb img, .testimonial-user .thumb img, .post-item__thumb img, .blog-details-thumb img, .support-upload-field__right button.btn, .campaign-details-top .thumb img, .gallery-item .gallery-item-icon {
  width: 100%; }

.dark--overlay::after, .preloader__circle::after, .cmn-btn::after, .cmn-btn-2::after, .footer::after, .widget-organizer .thumb img, .hero::before, .hero .hero-img-slider .slick-list, .hero .hero-img-slider .slick-track, .hero .hero-img-slider .slider-track, .hero .hero-img-slider .single-slide img, .inner-hero::before, .campaign-section-layer::before, .campaign-item .thumb img, .progressbar .bar, .overview-area .left img, .volunteer-item .thumb img, .testimonial-user .thumb img, .post-item__thumb img, .blog-details-thumb img, .campaign-details-top .thumb img, .gallery-item .gallery-item-icon, .d-widget {
  height: 100%; }

.campaign-item:hover, .post-item:hover .post-item__thumb, .d-widget.style--two:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px); }

.campaign-item .bottom .single-amount, .overview-item, .volunteer-item .content, .d-widget {
  text-align: center; }

.t-company-thumb, .form--control, .select, .header .main-menu > li .sub-menu, .event-details-widget, .widget-organizer .thumb, .blog-details-thumb, .user-profile, .qr-code, .qr-code-copy-form .text-copy-btn, .single-reply {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }

.custom--card, .action-sidebar, .post-item__thumb, .account-wrapper, .campaign-details-top .thumb, .create-campaign-area, .d-widget {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px; }

.campaign-details-top {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px; }

.preloader__circle, .preloader__circle::after, .social-links li a, .price-slider .ui-slider .ui-slider-handle, .about-thumb2 .video-btn, .about-thumb2 .video-btn::after, .campaign-slider .slick-arrow, .testimonial-user .thumb, .campaign-details-amount-list .single-amount-list::after, .d-widget__icon {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%; }

.check-list li + li {
  margin-top: 0.9375rem; }

.check-list li {
  position: relative;
  padding-left: 2.1875rem; }
  .check-list li::before {
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Line Awesome Free';
    font-weight: 900;
    content: "\f058";
    font-size: 1.75rem;
    color: #f1b85c;
    margin-right: 0.5rem;
    line-height: 1; }

.number-list {
  list-style: decimal;
  padding-left: 1.125rem; }
  .number-list li + li {
    margin-top: 0.625rem; }

.disc-list li + li {
  margin-top: 0.625rem; }

.disc-list li {
  position: relative;
  padding-left: 0.9375rem; }
  .disc-list li::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 0.375rem;
    height: 0.375rem;
    margin-top: -0.1875rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #575757; }

.caption-list li {
  display: flex;
  flex-wrap: wrap;
  padding: 0.625rem 0;
  font-size: 0.9375rem;
  border-bottom: 1px dashed #dadce0; }
  .caption-list li:first-child {
    padding-top: 0; }
  .caption-list li:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .caption-list li .caption {
    width: 40%;
    font-family: "Playfair Display", serif;
    font-weight: 700;
    font-size: 0.875rem;
    position: relative; }
    .caption-list li .caption::after {
      position: absolute;
      content: ':';
      top: 0;
      right: 0; }
  .caption-list li .value {
    width: 60%;
    padding-left: 0.9375rem; }

.caption-list-two {
  padding: 0.625rem 0.9375rem;
  background-color: rgba(241, 184, 92, 0.1);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
  .caption-list-two li {
    font-family: "Playfair Display", serif;
    font-weight: 500;
    color: #082032;
    font-size: 0.875rem;
    padding: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px dashed #bec2c9; }
    .caption-list-two li:first-child {
      padding-top: 0; }
    .caption-list-two li:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .caption-list-two li .caption {
      width: 20%;
      position: relative;
      font-weight: 700;
      padding-right: 10px; }
      .caption-list-two li .caption::after {
        position: absolute;
        content: ':';
        top: 0;
        right: 0; }
    .caption-list-two li .value {
      width: 80%;
      padding-left: 20px; }

.shake {
  animation: shake 0.5s 1 linear; }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

@-moz-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

@-ms-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-moz-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-ms-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-moz-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-ms-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

h1 {
  font-size: 3.875rem; }

h2 {
  font-size: 2rem; }
  @media (max-width: 991px) {
    h2 {
      font-size: 1.875rem; } }
  @media (max-width: 575px) {
    h2 {
      font-size: 1.75rem; } }

h3 {
  font-size: 1.75rem; }
  @media (max-width: 1199px) {
    h3 {
      font-size: 1.625rem; } }
  @media (max-width: 767px) {
    h3 {
      font-size: 1.5rem; } }
  @media (max-width: 575px) {
    h3 {
      font-size: 1.375rem; } }

h4 {
  font-size: 1.5rem; }
  @media (max-width: 767px) {
    h4 {
      font-size: 1.25rem; } }

h5 {
  font-size: 1.25rem; }
  @media (max-width: 767px) {
    h5 {
      font-size: 1.125rem; } }

h6 {
  font-size: 1.125rem; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", serif;
  color: #082032;
  font-weight: 600;
  margin: 0;
  line-height: 1.3;
  word-break: break-word; }

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  font-family: "Playfair Display", serif;
  color: #082032;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 1.4;
  word-break: break-word; }

p, li, span {
  margin: 0; }

a {
  text-decoration: none;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-weight: 400; }

a:hover {
  text-decoration: none; }

strong {
  font-weight: 500; }

.fs--18px {
  font-size: 1.125rem !important; }

.fs--16px {
  font-size: 1rem !important; }

.fs--15px {
  font-size: 0.9375rem !important; }

.fs--14px {
  font-size: 0.875rem !important; }

.fs--12px {
  font-size: 0.75rem !important; }

.h--font {
  font-family: "Playfair Display", serif !important; }

.p--font {
  font-family: "Poppins", sans-serif !important; }

.fw-medium {
  font-weight: 600 !important; }

/* button css start */
button:focus {
  outline: none; }

.btn {
  padding: 0.75rem 1.875rem;
  font-weight: 500; }
  .btn:focus {
    box-shadow: none; }

.btn--primary {
  background-color: #7367f0; }
  .btn--primary:hover {
    background-color: #5e50ee; }

.btn--secondary {
  background-color: #868e96; }
  .btn--secondary:hover {
    background-color: #78818a; }

.btn--success {
  background-color: #28c76f; }
  .btn--success:hover {
    background-color: #24b263; }

.btn--danger {
  background-color: #ea5455; }
  .btn--danger:hover {
    background-color: #e73d3e; }

.btn--warning {
  background-color: #ff9f43; }
  .btn--warning:hover {
    background-color: #ff922a; }

.btn--info {
  background-color: #1e9ff2; }
  .btn--info:hover {
    background-color: #0d93e9; }

.btn--light {
  background-color: #bcc7da; }
  .btn--light:hover {
    background-color: #acb9d1; }

.btn--dark {
  background-color: #082032; }
  .btn--dark:hover {
    background-color: #04121c; }

.btn--link {
  color: #7367f0; }

.btn--base:hover {
  background-color: #efae45; }

.cmn-btn, .cmn-btn-2 {
  padding: 0.875rem 2.1875rem;
  font-weight: 500; }
  .cmn-btn:hover, .cmn-btn-2:hover {
    box-shadow: 3px 3px 0 1px #f1b85c; }
    .cmn-btn:hover::after, .cmn-btn-2:hover::after {
      opacity: 1;
      top: 0;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg); }
  .cmn-btn::after, .cmn-btn-2::after {
    content: '';
    top: -200%;
    left: -10%;
    width: calc(130%);
    -webkit-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg);
    opacity: 0; }

.cmn-btn {
  box-shadow: 3px 3px 0 1px #475443; }
  .cmn-btn:hover {
    box-shadow: 3px 3px 0 1px #f1b85c; }
  .cmn-btn::after {
    background-color: #475443; }

.cmn-btn-2 {
  background-color: #475443;
  box-shadow: 3px 3px 0 1px #f1b85c; }
  .cmn-btn-2:hover {
    box-shadow: 3px 3px 0 1px #475443; }
  .cmn-btn-2::after {
    background-color: #f1b85c; }

.read-more-btn:hover::before {
  height: 100%; }

.read-more-btn::before {
  content: '';
  bottom: 0;
  left: 0;
  height: 4px;
  opacity: 0.25; }

.btn-outline--primary {
  color: #7367f0;
  border-color: #7367f0; }
  .btn-outline--primary:hover {
    background-color: #7367f0; }

.btn-outline--secondary {
  color: #868e96;
  border-color: #868e96; }
  .btn-outline--secondary:hover {
    background-color: #868e96; }

.btn-outline--success {
  color: #28c76f;
  border-color: #28c76f; }
  .btn-outline--success:hover {
    background-color: #28c76f; }

.btn-outline--danger {
  color: #ea5455;
  border-color: #ea5455; }
  .btn-outline--danger:hover {
    background-color: #ea5455; }

.btn-outline--warning {
  color: #ff9f43;
  border-color: #ff9f43; }
  .btn-outline--warning:hover {
    background-color: #ff9f43; }

.btn-outline--info {
  color: #1e9ff2;
  border-color: #1e9ff2; }
  .btn-outline--info:hover {
    background-color: #1e9ff2; }

.btn-outline--light {
  color: #bcc7da;
  border-color: #bcc7da; }
  .btn-outline--light:hover {
    background-color: #bcc7da; }

.btn-outline--dark {
  color: #082032;
  border-color: #082032; }
  .btn-outline--dark:hover {
    background-color: #082032; }

.btn-outline--base {
  color: #f1b85c;
  border: 1px solid #f1b85c; }
  .btn-outline--base:hover {
    background-color: #f1b85c; }

.btn-shadow--primary {
  box-shadow: 0 0 6px 1px rgba(115, 103, 240, 0.35); }

.btn-shadow--secondary {
  box-shadow: 0 0 6px 1px rgba(134, 142, 150, 0.35); }

.btn-shadow--success {
  box-shadow: 0 0 6px 1px rgba(40, 199, 111, 0.35); }

.btn-shadow--danger {
  box-shadow: 0 0 6px 1px rgba(234, 84, 85, 0.35); }

.btn-shadow--warning {
  box-shadow: 0 0 6px 1px rgba(255, 159, 67, 0.35); }

.btn-shadow--info {
  box-shadow: 0 0 6px 1px rgba(30, 159, 242, 0.35); }

.btn-shadow--light {
  box-shadow: 0 0 6px 1px rgba(188, 199, 218, 0.35); }

.btn-shadow--dark {
  box-shadow: 0 0 6px 1px rgba(8, 32, 50, 0.35); }

.btn-shadow--base {
  box-shadow: 0 0 6px 1px rgba(241, 184, 92, 0.35); }

.btn--capsule {
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px; }

.icon-btn {
  width: 1.875rem;
  height: 1.875rem;
  background-color: #f1b85c;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center; }

.btn--group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  .btn--group *[class*="btn"] {
    margin: 0.3125rem 0.625rem;
    align-items: center; }
    .btn--group *[class*="btn"].d-flex {
      padding: 0.5rem 2.1875rem; }
  .btn--group.style--two {
    margin-left: -0.3125rem;
    margin-right: -0.3125rem; }
    .btn--group.style--two *[class*="btn"] {
      margin: 0.1875rem 0.3125rem; }

[class*="btn"].btn-md {
  padding: 0.625rem 1.25rem;
  font-size: 0.9375rem; }

[class*="btn"].btn-sm {
  padding: 0.375rem 0.625rem;
  font-size: 0.875rem; }

/* button css end */
.badge--primary {
  background-color: rgba(115, 103, 240, 0.15);
  border: 1px solid #7367f0;
  color: #7367f0; }

.badge--secondary {
  background-color: rgba(134, 142, 150, 0.15);
  border: 1px solid #868e96;
  color: #868e96; }

.badge--success {
  background-color: rgba(40, 199, 111, 0.15);
  border: 1px solid #28c76f;
  color: #28c76f; }

.badge--danger {
  background-color: rgba(234, 84, 85, 0.15);
  border: 1px solid #ea5455;
  color: #ea5455; }

.badge--warning {
  background-color: rgba(255, 159, 67, 0.15);
  border: 1px solid #ff9f43;
  color: #ff9f43; }

.badge--info {
  background-color: rgba(30, 159, 242, 0.15);
  border: 1px solid #1e9ff2;
  color: #1e9ff2; }

.badge--light {
  background-color: rgba(188, 199, 218, 0.15);
  border: 1px solid #bcc7da;
  color: #bcc7da; }

.badge--dark {
  background-color: rgba(8, 32, 50, 0.15);
  border: 1px solid #082032;
  color: #082032; }

.badge--base {
  background-color: rgba(241, 184, 92, 0.15);
  border: 1px solid #f1b85c;
  color: #f1b85c; }

/* table css start */
.custom--table thead th {
  border-top: 1px solid #e9ecef !important;
  border-bottom: 1px solid #e9ecef !important;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  vertical-align: middle; }
  .custom--table thead th:first-child {
    text-align: left; }
  .custom--table thead th:last-child {
    text-align: right; }

.custom--table tbody td {
  border-top: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 15px 20px;
  color: #666666;
  text-align: center;
  vertical-align: middle; }
  .custom--table tbody td:first-child {
    text-align: left; }
  .custom--table tbody td:last-child {
    text-align: right; }

.custom--table tbody tr:last-child td {
  border-bottom: none; }

.t-company-thumb {
  width: 55px;
  height: 55px;
  overflow: hidden;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3); }

.t-company-content {
  padding-left: 0.9375rem; }

.t-rating img {
  max-height: 25px; }

[data-label] {
  position: relative; }
  [data-label]::before {
    position: absolute;
    content: attr(data-label);
    font-weight: 700;
    top: 0;
    left: 0;
    padding: 0.8125rem 0.9375rem;
    display: none;
    font-size: 0.875rem; }

@media (max-width: 991px) {
  .table-responsive--md thead {
    display: none; }
  .table-responsive--md tbody tr:nth-child(odd) {
    background-color: whitesmoke; }
  .table-responsive--md tbody tr:last-child td {
    border-top: 1px solid rgba(0, 0, 0, 0.08); }
  .table-responsive--md tbody tr td {
    padding-right: 15px; }
    .table-responsive--md tbody tr td:last-child {
      padding-right: 15px; }
  .table-responsive--md tr th, .table-responsive--md tr td {
    display: block;
    padding-left: 45% !important;
    text-align: right !important; }
    .table-responsive--md tr th:first-child, .table-responsive--md tr td:first-child {
      border-top: none !important; }
  .table-responsive--md [data-label]::before {
    display: block; } }

@media (max-width: 767px) {
  .table-responsive--sm thead {
    display: none; }
  .table-responsive--sm tbody tr:nth-child(odd) {
    background-color: f7f7f7; }
  .table-responsive--sm tbody tr td {
    padding-right: 15px; }
    .table-responsive--sm tbody tr td:last-child {
      padding-right: 15px; }
  .table-responsive--sm tr th, .table-responsive--sm tr td {
    display: block;
    padding-left: 45% !important;
    text-align: right !important;
    border-top: 1px solid rgba(0, 0, 0, 0.08) !important; }
    .table-responsive--sm tr th:first-child, .table-responsive--sm tr td:first-child {
      border-top: none !important; }
  .table-responsive--sm [data-label]::before {
    display: block; } }

@media (max-width: 1199px) {
  *[class*="table-responsive--"].data-label--none tr th, *[class*="table-responsive--"].data-label--none tr td {
    padding-left: 0.75rem; } }

/* table css end */
/* form css start */
.form-group {
  margin-bottom: 0.9375rem; }

.form-control:focus {
  border-color: #f1b85c !important;
  box-shadow: 0 0 5px rgba(241, 184, 92, 0.35); }

.form--control {
  padding: 0.625rem 1.25rem;
  border: 1px solid #dadce0;
  color: #363636;
  height: 3.4375rem; }
  .form--control::-webkit-input-placeholder {
    color: #7c7c7c; }
  .form--control::-moz-placeholder {
    color: #7c7c7c; }
  .form--control:-ms-input-placeholder {
    color: #7c7c7c; }
  .form--control:-moz-placeholder {
    color: #7c7c7c; }
  .form--control:focus {
    border-color: #f1b85c !important;
    box-shadow: 0 0 5px rgba(241, 184, 92, 0.35); }
  .form--control.style--dark {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.15); }
  .form--control[readonly] {
    background-color: #fafaf7; }
  .form--control.form-control-md {
    height: 45px; }
  .form--control.form-control-sm {
    height: 35px; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #082032;
  transition: background-color 5000s ease-in-out 0s; }

select.style--dark option {
  color: #363636; }

.custom-icon-field i {
  top: 15px;
  left: 12px; }

.custom-icon-field i[class*="la-"] {
  font-size: 1.5625rem; }

.custom-icon-field .form--control {
  padding-left: 2.8125rem; }

.select {
  padding: 0.625rem 1.25rem;
  border: 1px solid #cccfd4;
  cursor: pointer;
  color: #666666;
  height: 3.4375rem; }
  .select option {
    padding: 0.625rem 0;
    display: block;
    border-top: 1px solid #e5e5e5; }
  .select.select--sm {
    height: 2.1875rem;
    font-size: 0.875rem;
    padding: 0.3125rem; }

textarea {
  min-height: 9.375rem !important;
  resize: none;
  width: 100%; }

label {
  color: #082032;
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500; }

.input-group > .form--control,
.input-group > .select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0; }

.input-group select {
  background-color: transparent;
  border: none; }

.custom--radio {
  position: relative;
  padding-left: 0; }
  .custom--radio input[type=radio] {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    cursor: pointer; }
    .custom--radio input[type=radio]:checked ~ label::before {
      border-width: 2px;
      border-color: #f1b85c; }
    .custom--radio input[type=radio]:checked ~ label::after {
      opacity: 1; }
  .custom--radio label {
    margin-bottom: 0;
    position: relative;
    padding-left: 20px;
    font-size: 0.875rem;
    font-weight: 400; }
    .custom--radio label::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid #888888;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    .custom--radio label::after {
      position: absolute;
      content: '';
      top: 8px;
      left: 4px;
      width: 7px;
      height: 7px;
      background-color: #f1b85c;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      opacity: 0;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }

.custom--checkbox {
  padding-left: 1.5625rem; }
  .custom--checkbox input {
    display: none; }
    .custom--checkbox input:checked ~ label::before {
      content: "\f14a";
      color: #f1b85c; }
  .custom--checkbox label {
    position: relative;
    font-size: 0.9375rem;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 0; }
    .custom--checkbox label::before {
      position: absolute;
      content: "\f04d";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      top: 3px;
      left: -1.5625rem;
      font-size: 1.25rem;
      line-height: 1; }

/* form css end*/
/* card css start */
.custom--card {
  box-shadow: 0 3px 15px #8898aa26;
  overflow: hidden; }
  .custom--card .card-header {
    background-color: #fff;
    padding: 0.9375rem 1.25rem;
    border-color: #dadce0; }
  .custom--card .card-body {
    padding: 1.25rem; }
    @media (max-width: 575px) {
      .custom--card .card-body {
        padding: 0.9375rem; } }

/* card css end */
/* modal css start */
.modal {
  z-index: 999999; }

.modal-open {
  overflow: hidden;
  overflow-y: auto;
  padding-right: 0 !important; }

.btn-close {
  width: 18px;
  height: 18px;
  background-color: #ea5455;
  opacity: 1; }
  .btn-close:hover {
    opacity: 1; }
  .btn-close:focus {
    box-shadow: none; }

.modal.fade .modal-dialog {
  transform: scale(0.85, 0.85) translate(0); }

.modal.show .modal-dialog {
  transform: scale(1, 1) translate(0); }

/* modal css end */
.faq-single {
  border-bottom: 1px solid #dadce0; }
  .faq-single:last-child {
    border-bottom: none; }
  .faq-single__header {
    cursor: pointer;
    padding: 20px; }
    .faq-single__header::after {
      content: "\f067";
      top: 21px;
      right: 20px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 0.875rem; }
  .faq-single__content {
    display: none;
    padding: 20px; }
  .faq-single__title {
    font-size: 1rem;
    padding-right: 20px;
    font-weight: 500; }
    @media (max-width: 420px) {
      .faq-single__title {
        font-size: 1rem; } }
  .faq-single.active .faq-single__content {
    background-color: #f6f5ef80; }
  .faq-single.active .faq-single__header::after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.custom--nav-tabs {
  border-bottom: none; }
  .custom--nav-tabs .nav-item .nav-link {
    background-color: #fff;
    border: none;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    padding: 0.75rem 1.5625rem;
    font-weight: 500; }
    .custom--nav-tabs .nav-item .nav-link.active {
      background-color: #f1b85c;
      color: #fff; }

.pagination {
  margin: -0.3125rem -0.4375rem;
  flex-wrap: wrap;
  margin-top: 1.25rem;
  justify-content: flex-end; }
  .pagination .page-item {
    margin: 0.3125rem 0.4375rem; }
    .pagination .page-item.active .page-link {
      background-color: #f1b85c;
      color: #fff;
      border-color: #f1b85c; }
    .pagination .page-item .page-link {
      width: 2.375rem;
      height: 2.375rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: #666666; }
      .pagination .page-item .page-link:hover {
        background-color: #f1b85c;
        border-color: #f1b85c;
        color: #fff; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes upDown {
  0% {
    top: 0%; }
  25% {
    top: 50%; }
  50% {
    top: 100%; }
  75% {
    top: 50%; }
  100% {
    top: 0%; } }

@-moz-keyframes upDown {
  0% {
    top: 0%; }
  25% {
    top: 50%; }
  50% {
    top: 100%; }
  75% {
    top: 50%; }
  100% {
    top: 0%; } }

@-ms-keyframes upDown {
  0% {
    top: 0%; }
  25% {
    top: 50%; }
  50% {
    top: 100%; }
  75% {
    top: 50%; }
  100% {
    top: 0%; } }

@keyframes upDown {
  0% {
    top: 0%; }
  25% {
    top: 50%; }
  50% {
    top: 100%; }
  75% {
    top: 50%; }
  100% {
    top: 0%; } }

@-webkit-keyframes downUp {
  0% {
    bottom: 0%; }
  25% {
    bottom: 50%; }
  50% {
    bottom: 100%; }
  75% {
    bottom: 50%; }
  100% {
    bottom: 0%; } }

@-moz-keyframes downUp {
  0% {
    bottom: 0%; }
  25% {
    bottom: 50%; }
  50% {
    bottom: 100%; }
  75% {
    bottom: 50%; }
  100% {
    bottom: 0%; } }

@-ms-keyframes downUp {
  0% {
    bottom: 0%; }
  25% {
    bottom: 50%; }
  50% {
    bottom: 100%; }
  75% {
    bottom: 50%; }
  100% {
    bottom: 0%; } }

@keyframes downUp {
  0% {
    bottom: 0%; }
  25% {
    bottom: 50%; }
  50% {
    bottom: 100%; }
  75% {
    bottom: 50%; }
  100% {
    bottom: 0%; } }

/* header start */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9; }
  .header__bottom {
    background-color: transparent;
    padding-left: 3.125rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
    @media (max-width: 1199px) {
      .header__bottom {
        padding: 0.625rem 1.875rem; } }
  .header .site-logo img {
    max-width: 12.5rem;
    max-height: 3.125rem; }
    @media (max-width: 1199px) {
      .header .site-logo img {
        max-width: 9.375rem; } }
  .header .site-logo.site-title {
    font-size: 1.5rem; }
  .header .main-menu {
    margin-left: 4.375rem; }
    @media (max-width: 1199px) {
      .header .main-menu {
        margin-left: 0;
        padding: 0.9375rem 0; } }
    .header .main-menu > li {
      position: relative; }
      @media (max-width: 1199px) {
        .header .main-menu > li {
          border-bottom: 1px solid rgba(255, 255, 255, 0.15); } }
      .header .main-menu > li.active::after, .header .main-menu > li:hover::after {
        opacity: 1; }
      .header .main-menu > li::after {
        content: '';
        bottom: -2px;
        left: 0;
        height: 2px;
        opacity: 0; }
      .header .main-menu > li:last-child a {
        padding-right: 0; }
      .header .main-menu > li.menu_has_children {
        position: relative; }
        .header .main-menu > li.menu_has_children.open > .sub-menu {
          display: block; }
        .header .main-menu > li.menu_has_children > a {
          padding-right: 1.5625rem; }
          @media (max-width: 1199px) {
            .header .main-menu > li.menu_has_children > a {
              display: block; } }
          .header .main-menu > li.menu_has_children > a::before {
            position: absolute;
            content: "\f107";
            font-family: "Line Awesome Free";
            font-weight: 900;
            top: 1.9375rem;
            right: 5px;
            color: #fff;
            font-size: 0.875rem; }
            @media (max-width: 1199px) {
              .header .main-menu > li.menu_has_children > a::before {
                display: block;
                top: 0.5625rem;
                color: #fff; } }
        .header .main-menu > li.menu_has_children:hover > a::before {
          color: #f1b85c;
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
      .header .main-menu > li a {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        padding: 1.75rem 0.9375rem;
        color: #fff; }
        @media (max-width: 1199px) {
          .header .main-menu > li a {
            color: #fff;
            padding: 0.5rem 0;
            display: block; } }
        .header .main-menu > li a:hover, .header .main-menu > li a:focus {
          color: #f1b85c; }
      .header .main-menu > li .sub-menu {
        position: absolute;
        width: 220px;
        top: 105%;
        left: 0;
        z-index: 9999;
        padding: 0.625rem 0;
        box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
        opacity: 0;
        visibility: hidden; }
        @media (max-width: 1199px) {
          .header .main-menu > li .sub-menu {
            opacity: 1;
            visibility: visible;
            display: none;
            position: static;
            -webkit-transition: none;
            -o-transition: none;
            transition: none;
            width: 100%;
            background-color: #1a2a4a;
            border: none;
            padding-left: 0.9375rem;
            padding-top: 0; } }
        .header .main-menu > li .sub-menu li {
          border-bottom: 1px dashed rgba(255, 255, 255, 0.25); }
          @media (max-width: 1199px) {
            .header .main-menu > li .sub-menu li {
              border-color: rgba(255, 255, 255, 0.15); } }
          .header .main-menu > li .sub-menu li:last-child {
            border-bottom: none; }
          .header .main-menu > li .sub-menu li a {
            padding: 0.375rem 1.5625rem;
            display: block;
            color: #666666;
            position: relative;
            font-size: 0.9375rem;
            text-transform: capitalize; }
            @media (max-width: 1199px) {
              .header .main-menu > li .sub-menu li a {
                color: #fff;
                padding: 0.5rem 0; } }
        .header .main-menu > li .sub-menu li + li {
          margin-left: 0; }
      .header .main-menu > li:hover > .sub-menu {
        top: 95%;
        opacity: 1;
        visibility: visible; }
    .header .main-menu li + li {
      margin-left: 1.25rem; }
      @media (max-width: 1199px) {
        .header .main-menu li + li {
          margin-left: 0; } }
    .header .main-menu .sub-menu li.menu_has_children > a::before {
      top: 9px;
      right: 8px; }
    .header .main-menu .sub-menu li.menu_has_children > .sub-menu {
      left: 100%;
      top: 0; }
      @media (max-width: 1199px) {
        .header .main-menu .sub-menu li.menu_has_children > .sub-menu {
          padding-left: 15px; } }
  .header .nav-right {
    padding-left: 3.125rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center; }
    @media (max-width: 1199px) {
      .header .nav-right {
        padding-left: 0; } }
  .header__search-btn {
    background-color: transparent;
    padding: 0;
    font-size: 1.375rem;
    margin-right: 1.25rem; }
    @media (max-width: 1199px) {
      .header__search-btn {
        display: none; } }
    .header__search-btn.active i {
      transform: rotate(0); }
      .header__search-btn.active i::before {
        content: "\f00d"; }

.language-select {
  background-color: transparent;
  border: none;
  font-size: 0.875rem;
  padding: 0;
  margin-right: 0.9375rem; }

.header-btn {
  padding: 1.875rem; }
  .header-btn:hover {
    background-color: #475443; }

@media (max-width: 1199px) {
  .navbar-collapse {
    margin-top: 20px !important;
    background-color: #1a2a4a;
    padding: 0 1.875rem 1.25rem 1.875rem; } }

@media (max-width: 767px) {
  .navbar-collapse {
    max-height: 20rem;
    overflow: auto; } }

.navbar-toggler {
  padding: 0;
  border: none; }
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none; }

.menu-toggle {
  margin: 10px 0;
  position: relative;
  display: block;
  width: 2.1875rem;
  height: 1.25rem;
  cursor: pointer;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #fff;
  font-size: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer; }

.menu-toggle:before, .menu-toggle:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: -webkit-transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  -moz-transition: -webkit-transform 0.25s ease-in-out;
  -ms-transition: -webkit-transform 0.25s ease-in-out; }
  @media (max-width: 1199px) {
    .menu-toggle:before, .menu-toggle:after {
      background-color: #fff; } }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

/* header end */
/* footer sectioncss start */
.footer::after {
  content: '';
  top: 0;
  left: 0;
  opacity: 0.85; }

.footer .bg-el {
  bottom: 0;
  left: 0; }

.footer p, .footer span, .footer a {
  color: rgba(255, 255, 255, 0.8); }

.footer__top {
  padding: 3.125rem 0; }

.footer__logo img {
  max-height: 50px;
  max-width: 200px; }

.footer__bottom {
  padding: 0.9375rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15); }

.inline-menu {
  margin: -0.3125rem -0.625rem; }
  .inline-menu li {
    padding: 0.3125rem 0.625rem; }

.social-links {
  margin: -0.125rem -0.4375rem; }
  .social-links li {
    padding: 0.125rem 0.4375rem;
    font-size: 1.125rem; }
    .social-links li a {
      width: 35px;
      height: 35px;
      background-color: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.15); }
      .social-links li a:hover {
        border-color: #f1b85c; }

.footer-widget__title {
  font-size: 1.125rem; }
  .footer-widget__title::after {
    content: '';
    bottom: 0;
    left: 0; }

.footer-widget__body {
  margin-top: 1.25rem; }

.footer-widget__menu li:last-child a {
  border-bottom: none; }

.footer-widget__menu li a {
  padding: 0.625rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: block; }

.footer-widget__contact-list li {
  padding: 0.625rem 0; }
  .footer-widget__contact-list li .icon {
    width: 40px;
    font-size: 1.5rem;
    line-height: 1; }
  .footer-widget__contact-list li .content {
    width: calc(100% - 40px); }

/* footer sectioncss end */
/* event-details-sidebar css start */
.event-details-sidebar {
  position: sticky;
  top: 30px; }

.event-details-widget {
  padding: 0.9375rem;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05); }

.widget-organizer .thumb {
  width: 60px;
  height: 60px; }

.widget-organizer .content {
  width: calc(100% - 60px);
  padding-left: 0.9375rem; }

.widget-organizer .organizer-name {
  font-size: 1.125rem; }

.event-wiget-info li {
  padding: 0.625rem 0;
  border-bottom: 1px dashed white; }
  .event-wiget-info li i {
    width: 40px;
    font-size: 28px; }
  .event-wiget-info li .content {
    width: calc(100% - 40px);
    padding-left: 0.625rem;
    color: #082032;
    margin-top: -3px; }

.social-share {
  margin: -0.125rem -0.25rem; }
  .social-share li {
    padding: 0.125rem 0.25rem; }
  .social-share a {
    width: 35px;
    height: 35px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px; }

@media (max-width: 991px) {
  .action-sidebar {
    padding: 30px;
    min-height: 100vh;
    width: 350px;
    position: fixed;
    top: 0;
    left: -105%;
    z-index: 99999;
    max-width: 100%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    max-height: calc(100vh - 75px);
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: darkgrey #e7e7e7; }
    .action-sidebar::-webkit-scrollbar {
      width: 5px; }
    .action-sidebar::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
    .action-sidebar::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 999px;
      -webkit-border-radius: 999px;
      -moz-border-radius: 999px;
      -ms-border-radius: 999px;
      -o-border-radius: 999px; }
    .action-sidebar.active {
      left: 0; } }

@media (max-width: 575px) {
  .action-sidebar {
    padding: 20px 15px; } }

.action-widget {
  padding: 20px 0;
  border-bottom: 1px solid #e2e4e7; }
  .action-widget:first-child {
    padding-top: 0; }
  .action-widget:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .action-widget__title {
    font-size: 0.9375rem;
    color: #082032;
    position: relative;
    padding-right: 20px;
    cursor: pointer; }
    .action-widget__title::after {
      position: absolute;
      content: "\f107";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      top: 0;
      right: 0;
      font-size: 0.875rem; }
  .action-widget__body {
    margin-top: 0.9375rem; }
    .action-widget__body.scroll--active {
      max-height: 200px;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: darkgrey #e7e7e7; }
      .action-widget__body.scroll--active::-webkit-scrollbar {
        width: 5px; }
      .action-widget__body.scroll--active::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
      .action-widget__body.scroll--active::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 999px;
        -webkit-border-radius: 999px;
        -moz-border-radius: 999px;
        -ms-border-radius: 999px;
        -o-border-radius: 999px; }

.search-form-inline {
  position: relative; }
  .search-form-inline input {
    padding-left: 10px;
    padding-right: 30px; }
  .search-form-inline__btn {
    position: absolute;
    top: 50%;
    right: 5px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: transparent; }

.action-sidebar-close {
  width: 30px;
  height: 30px;
  background-color: #f1b85c;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  display: none; }
  @media (max-width: 991px) {
    .action-sidebar-close {
      display: inline-block; } }

.action-sidebar-open {
  padding: 0.5rem 1.5625rem;
  background-color: #f1b85c;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  display: none; }
  @media (max-width: 991px) {
    .action-sidebar-open {
      display: flex; } }
  .action-sidebar-open i {
    font-size: 1.375rem;
    margin-right: 5px; }

#filter-domain-add-list {
  margin-top: 5px; }
  #filter-domain-add-list a {
    margin: 3px;
    padding: 0.1875rem 0.625rem;
    border: 1px solid #dadce0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 0.8125rem; }

.price-slider {
  flex-direction: column; }
  .price-slider .slider-range, .price-slider .slider-range-two {
    order: 2;
    border: none !important;
    height: 5px;
    background-color: #cacaca;
    margin: 0 8px; }
  .price-slider .amount__field {
    justify-content: space-between;
    margin-bottom: 4px; }
    .price-slider .amount__field label {
      margin-bottom: 0;
      font-weight: 400; }
  .price-slider .range-amount {
    text-align: right;
    padding: 0;
    font-weight: 600;
    font-size: 0.875rem;
    border: none;
    width: 150px; }
  .price-slider .ui-widget-header {
    background: #ececec; }
  .price-slider .ui-slider .ui-slider-handle {
    background: #fff;
    width: 15px;
    height: 15px;
    top: -6px;
    border: 1px solid #f1b85c;
    cursor: pointer; }

/* event-details-sidebar css end */
/* hero section css start */
.hero {
  padding-top: 360px;
  padding-bottom: 280px; }
  @media (max-width: 1399px) {
    .hero {
      padding-top: 275px;
      padding-bottom: 185px; } }
  @media (max-width: 1199px) {
    .hero {
      padding-top: 230px; } }
  @media (max-width: 991px) {
    .hero {
      padding-top: 200px;
      padding-bottom: 135px; } }
  @media (max-width: 767px) {
    .hero {
      padding-top: 175px;
      padding-bottom: 115px; } }
  .hero::before {
    content: '';
    top: 0;
    left: 0;
    opacity: 0.65;
    z-index: -1; }
  .hero__top, .hero__left, .hero__right {
    z-index: -1; }
  .hero__top {
    top: -150px;
    left: -80px;
    width: 400px; }
    @media (max-width: 991px) {
      .hero__top {
        max-width: 300px; } }
  .hero__left {
    bottom: -195px;
    left: -185px; }
    @media (max-width: 991px) {
      .hero__left {
        max-width: 400px; } }
    @media (max-width: 767px) {
      .hero__left {
        max-width: 300px; } }
  .hero__right {
    bottom: -125px;
    right: -135px; }
    @media (max-width: 991px) {
      .hero__right {
        max-width: 550px; } }
    @media (max-width: 767px) {
      .hero__right {
        max-width: 450px; } }
  .hero__top-title {
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 3px; }
    @media (max-width: 575px) {
      .hero__top-title {
        font-size: 1rem; } }
  .hero__title {
    font-family: "Permanent Marker", cursive;
    font-size: 4.5rem; }
    @media (max-width: 1399px) {
      .hero__title {
        font-size: 4.125rem; } }
    @media (max-width: 1199px) {
      .hero__title {
        font-size: 3.5rem; } }
    @media (max-width: 767px) {
      .hero__title {
        font-size: 2.625rem; } }
    @media (max-width: 575px) {
      .hero__title {
        font-size: 2rem; } }
  .hero .hero-img-slider {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2; }
    .hero .hero-img-slider .slider-track {
      -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1); }
    .hero .hero-img-slider .single-slide {
      height: 100%;
      position: relative;
      z-index: 1; }
      .hero .hero-img-slider .single-slide img {
        -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        -webkit-transform: scale(1.2);
        transform: scale(1.2); }
      .hero .hero-img-slider .single-slide.slick-active img {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation: fadeAnimation 8s 1 ease-in-out forwards;
        animation: fadeAnimation 8s 1 ease-in-out forwards; }

@keyframes fadeAnimation {
  from {
    -webkit-transform: scale(1) translate(0px); }
  to {
    -webkit-transform: scale(1.2) translate(0px); } }

@-webkit-keyframes fadeAnimation {
  from {
    -webkit-transform: scale(1) translate(0px); }
  to {
    -webkit-transform: scale(1.2) translate(0px); } }

/* hero section css end */
/* inner hero section css start */
.inner-hero {
  padding-top: 150px;
  padding-bottom: 70px; }
  .inner-hero::before {
    content: '';
    top: 0;
    left: 0;
    opacity: 0.75; }

.page-breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 15px; }
  .page-breadcrumb li {
    color: rgba(255, 255, 255, 0.8);
    text-transform: capitalize; }
    .page-breadcrumb li::after {
      content: '-';
      color: #ffffff;
      margin: 0 5px; }
    .page-breadcrumb li:first-child::before {
      content: "\f015";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: #f1b85c;
      margin-right: 6px; }
    .page-breadcrumb li:last-child::after {
      display: none; }
    .page-breadcrumb li a {
      color: #ffffff;
      text-transform: capitalize; }
      .page-breadcrumb li a:hover {
        color: #f1b85c; }

/* inner hero section css end */
/* about section css start */
.about-thumb-area {
  padding-right: 70px;
  padding-bottom: 50px; }

.about-thumb2 {
  width: 250px;
  height: 165px;
  bottom: 0;
  right: 0;
  border: 5px solid #fff; }
  .about-thumb2 .video-btn {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 65px;
    height: 65px;
    font-size: 1.75rem; }
    .about-thumb2 .video-btn::after {
      content: '';
      top: -5px;
      left: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      opacity: 0.45; }

.founder .thumb {
  width: 70px;
  height: 65px; }

.founder .content {
  padding-left: 0.9375rem; }
  .founder .content .name {
    font-size: 1.25rem; }

/* about section css end */
/* campaign section css start */
.campaign-section-layer {
  top: 0;
  left: 0;
  height: 450px; }
  .campaign-section-layer::before {
    content: '';
    top: 0;
    left: 0;
    opacity: 0.7; }

.campaign-item {
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.05); }
  .campaign-item:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); }
  .campaign-item .thumb {
    height: 275px; }
    .campaign-item .thumb .progressbar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; }
  .campaign-item__tag {
    padding: 0.125rem 0.75rem;
    font-size: 0.8125rem; }
  .campaign-item .content .inner {
    padding: 1.5625rem; }
  .campaign-item .content .title {
    font-size: 1.25rem; }
    .campaign-item .content .title a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }
  .campaign-item .progressbar {
    margin-top: 2.5rem; }
  .campaign-item .bottom {
    padding: 0.9375rem 1.5625rem;
    border-top: 1px solid #dadce0; }
    @media (max-width: 575px) {
      .campaign-item .bottom {
        padding: 0.625rem 0.9375rem; } }
    .campaign-item .bottom .single-amount {
      width: calc(100% / 3);
      font-weight: 500;
      padding: 0 0.625rem; }
      .campaign-item .bottom .single-amount::after {
        content: '';
        top: 50%;
        right: 0;
        width: 1px;
        height: calc(100% - 10px);
        background-color: #dadce0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }
      .campaign-item .bottom .single-amount:last-child::after {
        display: none; }
      .campaign-item .bottom .single-amount .caption {
        font-size: 0.875rem; }
      .campaign-item .bottom .single-amount .amount {
        word-break: break-all; }

.progressbar {
  height: 6px;
  background-color: #eeeeee; }
  .progressbar .bar {
    width: 0px;
    top: 0;
    left: 0; }
  .progressbar .label {
    top: -30px;
    left: 0;
    width: 30px;
    height: 20px;
    font-size: 0.75rem;
    -webkit-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    transform: translateX(-15px); }
    .progressbar .label:before {
      content: '';
      bottom: -10px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 10px;
      height: 10px;
      border-top: 6px solid #f1b85c;
      border-bottom: 5px solid transparent;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent; }

.campaign-slider .slick-list {
  margin: -30px -15px; }

.campaign-slider .single-slide {
  padding: 30px 15px; }

.campaign-slider .slick-arrow {
  top: 50%;
  width: 50px;
  height: 50px;
  font-size: 1.375rem;
  border: 2px solid #dadce0;
  margin-top: -25px; }
  @media (max-width: 1550px) {
    .campaign-slider .slick-arrow {
      top: 30%; } }
  .campaign-slider .slick-arrow.prev {
    left: -85px; }
    @media (max-width: 1550px) {
      .campaign-slider .slick-arrow.prev {
        left: 5px; } }
  .campaign-slider .slick-arrow.next {
    right: -85px; }
    @media (max-width: 1550px) {
      .campaign-slider .slick-arrow.next {
        right: 5px; } }

/* campaign section css end */
/* why choose us section css start */
.choose-us-section::after {
  content: '';
  top: 0;
  right: 0;
  width: 50%;
  height: calc(100% - 100px);
  background-color: rgba(241, 184, 92, 0.05); }

.choose-right-content {
  padding-left: 3.125rem; }
  @media (max-width: 991px) {
    .choose-right-content {
      padding-left: 0; } }

.overview-area {
  margin-top: 3.125rem; }
  .overview-area .left {
    width: 50%; }
    @media (max-width: 575px) {
      .overview-area .left {
        width: 100%;
        height: 300px; } }
  .overview-area .right {
    width: 50%;
    padding: 3.125rem; }
    @media (max-width: 767px) {
      .overview-area .right {
        padding: 1.25rem; } }
    @media (max-width: 575px) {
      .overview-area .right {
        width: 100%; } }
    .overview-area .right .map-el {
      width: 75%;
      height: 300px;
      top: 50%;
      left: 50%;
      opacity: 0.5;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }

.overview-list::after {
  content: '';
  top: 50%;
  left: 50%;
  width: 1px;
  height: 120px;
  margin-top: -60px;
  background-color: rgba(0, 0, 0, 0.15); }

.overview-list::before {
  content: '';
  top: 50%;
  left: 50%;
  width: 120px;
  height: 1px;
  margin-left: -60px;
  background-color: rgba(0, 0, 0, 0.15); }

.overview-item {
  width: 50%;
  padding: 1.25rem; }
  .overview-item .amount {
    font-family: "Playfair Display", serif;
    font-weight: 700;
    font-size: 3rem;
    line-height: 1; }
    @media (max-width: 991px) {
      .overview-item .amount {
        font-size: 2.25rem; } }
    @media (max-width: 767px) {
      .overview-item .amount {
        font-size: 1.75rem; } }
  .overview-item .caption {
    font-weight: 500;
    margin-top: 0.625rem; }
    @media (max-width: 767px) {
      .overview-item .caption {
        font-size: 0.875rem; } }

/* why choose us section css end */
/* volunteer section css start */
.volunteer-section {
  justify-content: flex-end; }
  @media (max-width: 1199px) {
    .volunteer-section {
      justify-content: center; } }
  .volunteer-section .left {
    width: 450px;
    padding-left: 1.25rem; }
    @media (max-width: 1199px) {
      .volunteer-section .left {
        width: 100%;
        max-width: 700px;
        padding: 0 1.25rem;
        text-align: center;
        margin-bottom: 35px; } }
  .volunteer-section .right {
    width: 1075px;
    padding-left: 3.125rem; }
    @media (max-width: 1550px) {
      .volunteer-section .right {
        width: calc(100% - 450px); } }
    @media (max-width: 1199px) {
      .volunteer-section .right {
        width: 100%;
        padding: 0 2.5rem; } }

.volunteer-item .thumb {
  height: 220px; }

.volunteer-item .content {
  padding: 1.25rem;
  border: 1px solid #dadce0;
  border-top: none; }
  .volunteer-item .content .name {
    font-size: 1.375rem; }
  .volunteer-item .content .caption {
    font-size: 0.875rem; }

.volunteer-slider .slick-list {
  margin: 0 -7px; }

.volunteer-slider .single-slide {
  padding: 0 7px; }

.volunteer-slider .slick-arrow {
  top: 50%;
  left: -22px;
  width: 45px;
  height: 40px;
  font-size: 1.125rem;
  box-shadow: 0 3px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }
  .volunteer-slider .slick-arrow.prev {
    margin-top: -60px; }
    @media (max-width: 1199px) {
      .volunteer-slider .slick-arrow.prev {
        margin-top: 0;
        left: -20px; } }
  .volunteer-slider .slick-arrow.next {
    margin-bottom: -60px; }
    @media (max-width: 1199px) {
      .volunteer-slider .slick-arrow.next {
        margin-top: 0;
        left: auto;
        right: -20px; } }

/* volunteer section css end */
/* testimonial section css start */
.testimonial-section .left {
  width: 60%; }

.testimonial-section .right {
  width: 40%;
  padding-right: 15%; }
  @media (max-width: 1650px) {
    .testimonial-section .right {
      padding-right: 8%; } }
  @media (max-width: 1399px) {
    .testimonial-section .right {
      padding-right: 2.5rem; } }
  @media (max-width: 767px) {
    .testimonial-section .right {
      width: 100%;
      padding-left: 1.875rem;
      padding-right: 1.875rem; } }
  @media (max-width: 575px) {
    .testimonial-section .right {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; } }
  .testimonial-section .right .testimonial-header {
    padding-left: 2.5rem;
    margin-bottom: 2.5rem; }
    @media (max-width: 767px) {
      .testimonial-section .right .testimonial-header {
        text-align: center;
        padding: 0 1.25rem; } }

.testimonial-slider {
  padding: 4.375rem 3.125rem 3.125rem 3.125rem;
  margin-left: -100px; }
  @media (max-width: 991px) {
    .testimonial-slider {
      padding: 4.375rem 1.875rem 1.875rem 1.875rem; } }
  @media (max-width: 767px) {
    .testimonial-slider {
      margin-left: 0; } }
  .testimonial-slider::after {
    top: 15px;
    left: 43px;
    content: "\f10d";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    font-size: 56px;
    line-height: 1; }
    @media (max-width: 991px) {
      .testimonial-slider::after {
        left: 22px; } }

.testimonial-user {
  margin-top: 1.25rem; }
  .testimonial-user .thumb {
    width: 75px;
    height: 75px;
    border: 2px solid #1a2a4a; }
  .testimonial-user .content {
    padding-left: 1.25rem;
    width: calc(100% - 75px); }

/* testimonial section css end */
/* blog section css start */
.post-item__thumb {
  height: 275px;
  box-shadow: 0 8px 15px rgba(26, 42, 74, 0.15); }
  @media (max-width: 1199px) {
    .post-item__thumb {
      height: 220px; } }

.post-item__content {
  margin-top: 25px; }
  .post-item__content .title {
    font-size: 20px; }
    .post-item__content .title a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }

/* blog section css end */
/* blog details section css start */
.blog-details-thumb {
  max-height: 450px; }

.blog-details-title {
  font-size: 2rem; }
  @media (max-width: 991px) {
    .blog-details-title {
      font-size: 1.75rem; } }
  @media (max-width: 575px) {
    .blog-details-title {
      font-size: 1.5rem; } }

.post-share {
  padding: 1.25rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: -0.1875rem -0.3125rem; }
  .post-share li {
    padding: 0.1875rem 0.3125rem; }
    .post-share li a {
      width: 2.1875rem;
      height: 2.1875rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #666666; }
      .post-share li a:hover {
        border-color: #f1b85c; }

.blog-sidebar {
  padding: 1.25rem; }
  .blog-sidebar .title {
    position: relative;
    padding-bottom: 0.3125rem;
    margin-bottom: 1.875rem; }
    .blog-sidebar .title::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 2.1875rem;
      height: 0.125rem; }

.s-post {
  padding: 0.9375rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
  .s-post:first-child {
    padding-top: 0; }
  .s-post:last-child {
    padding-bottom: 0;
    border-bottom: none !important; }
  .s-post__thumb {
    width: 5rem;
    height: 4.375rem; }
    .s-post__thumb img {
      width: 100%;
      height: 100%; }
  .s-post__content {
    width: calc(100% - 80px);
    padding-left: 1.25rem; }
  .s-post__title {
    font-size: 1rem; }
    .s-post__title a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }

/* blog details section css end */
/* contact section css start */
.contact-list {
  margin-top: 33px; }
  .contact-list li + li {
    margin-top: 1.25rem; }
  .contact-list li .icon {
    width: 40px; }
    .contact-list li .icon i {
      font-size: 1.75rem; }
  .contact-list li .content {
    width: calc(100% - 40px); }

.social-links li a {
  border: 1px solid #dadce0; }

.map-area {
  margin-top: 1.875rem; }
  .map-area iframe {
    width: 100%;
    height: 247px; }

/* contact section css end */
/* account section css start */
.account-wrapper {
  padding: 2.5rem 1.875rem;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05); }
  @media (max-width: 575px) {
    .account-wrapper {
      padding: 1.875rem 1.25rem; } }

/* account section css end */
.user-profile {
  padding: 1.25rem;
  background-color: #fff; }
  @media (max-width: 1199px) {
    .user-profile {
      padding: 1.25rem; } }

.profile-thumb {
  position: relative;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  display: inline-flex; }
  .profile-thumb .profilePicPreview {
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    display: block;
    border: 2px solid #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    background-size: cover;
    background-position: top; }
  .profile-thumb .profilePicUpload {
    font-size: 0;
    opacity: 0; }
  .profile-thumb .avatar-edit {
    position: absolute;
    right: 0;
    bottom: -10px; }
    .profile-thumb .avatar-edit input {
      width: 0; }
    .profile-thumb .avatar-edit label {
      font-size: 0.75rem;
      padding: 0.0625rem 0.9375rem;
      border-radius: 999px;
      -webkit-border-radius: 999px;
      -moz-border-radius: 999px;
      -ms-border-radius: 999px;
      -o-border-radius: 999px;
      cursor: pointer; }

.profile-info-list li {
  padding: 0.875rem 0;
  border-bottom: 1px dashed #cccfd4;
  font-size: 0.9375rem; }
  @media (max-width: 575px) {
    .profile-info-list li {
      font-size: 0.875rem; } }
  .profile-info-list li:last-child {
    border-bottom: none;
    padding-bottom: 0; }
  .profile-info-list li .caption {
    width: 40%;
    font-weight: 600;
    display: flex;
    align-items: center; }
    .profile-info-list li .caption i {
      font-size: 1.25rem;
      width: 25px; }
  .profile-info-list li .details {
    width: 60%;
    padding-left: 10px; }

/* authentication section css start */
.qr-code-wrapper {
  padding: 1.25rem; }

.qr-code {
  padding: 0.625rem 0.625rem;
  background-color: #cecece73; }

.qr-code-copy-form input[type="text"] {
  background-color: transparent;
  border: none;
  font-size: 1.25rem;
  width: calc(100% - 75px);
  height: 40px; }
  @media (max-width: 440px) {
    .qr-code-copy-form input[type="text"] {
      font-size: 1rem; } }
  @media (max-width: 360px) {
    .qr-code-copy-form input[type="text"] {
      font-size: 0.875rem; } }

.qr-code-copy-form .text-copy-btn {
  width: 75px;
  background-color: #f1b85c;
  border: none; }

.qr-code-form .form--control {
  height: 4.0625rem;
  padding-right: 5.9375rem; }

.qr-code-form__btn {
  top: 10px;
  right: 10px;
  width: 75px;
  height: calc(100% - 20px); }

/* authentication section css end */
/* support section css start */
.custom--file-upload {
  position: relative;
  line-height: 2rem;
  font-size: 0.9375rem; }
  .custom--file-upload::before {
    position: absolute;
    content: 'Choose File';
    top: -1px;
    left: -1px;
    width: 115px;
    height: calc(100% + 2px);
    background-color: #f1b85c;
    padding: 0.625rem 1.25rem;
    line-height: 24px; }

.single-reply {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  padding: 1.875rem;
  background-color: #cacaca0f; }
  @media (max-width: 575px) {
    .single-reply {
      padding: 1.25rem; } }
  .single-reply .left {
    width: 20%; }
    @media (max-width: 767px) {
      .single-reply .left {
        width: 100%; } }
  .single-reply .right {
    width: 80%;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    padding-left: 1.25rem; }
    @media (max-width: 767px) {
      .single-reply .right {
        width: 100%;
        padding-left: 0;
        border-left: none;
        margin-top: 1.25rem; } }

.single-reply + .single-reply {
  margin-top: 20px; }

.support-upload-field__left {
  width: calc(100% - 80px);
  padding-right: 15px; }
  @media (max-width: 480px) {
    .support-upload-field__left {
      width: 100%;
      padding-right: 0; } }

.support-upload-field__right {
  width: 80px;
  padding-top: 33px; }
  @media (max-width: 480px) {
    .support-upload-field__right {
      width: 100%;
      padding-top: 0;
      order: -1;
      text-align: right;
      margin-bottom: -25px;
      position: relative;
      z-index: 1; } }
  .support-upload-field__right button.btn {
    padding: 0.625rem 1.5625rem; }
    @media (max-width: 480px) {
      .support-upload-field__right button.btn {
        padding: 3px 8px; } }

.single-file-upload .form-control {
  width: calc(100% - 60px);
  border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -ms-border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
  border-right: 0; }
  @media (max-width: 380px) {
    .single-file-upload .form-control {
      width: calc(100% - 30px); } }

.single-file-upload .btn {
  width: 60px;
  padding: 0.625rem;
  border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -ms-border-radius: 0 5px 5px 0;
  -o-border-radius: 0 5px 5px 0; }
  @media (max-width: 380px) {
    .single-file-upload .btn {
      padding: 5px;
      width: 30px; } }

/* support section css end */
/* campaign details section css start */
.campaign-details-top {
  padding: 1.875rem;
  background-color: #f7f7f7; }
  .campaign-details-top .thumb {
    max-height: 650px; }
  .campaign-details-top .title {
    font-size: 1.5rem;
    margin-top: 1.25rem; }
  .campaign-details-top .progressbar {
    margin-top: 2.5rem;
    height: 10px;
    background-color: #ebebeb;
    border-radius: 999px;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px; }
    .campaign-details-top .progressbar .bar {
      border-radius: 999px;
      -webkit-border-radius: 999px;
      -moz-border-radius: 999px;
      -ms-border-radius: 999px;
      -o-border-radius: 999px; }

.campaign-details-amount-list {
  margin: -10px -40px; }
  .campaign-details-amount-list .single-amount-list {
    padding: 10px 40px; }
    .campaign-details-amount-list .single-amount-list:last-child::before, .campaign-details-amount-list .single-amount-list:last-child::after {
      display: none; }
    .campaign-details-amount-list .single-amount-list::before {
      content: '';
      top: 50%;
      right: -20px;
      width: 40px;
      height: 2px;
      background-color: #e1e1e1; }
      @media (max-width: 380px) {
        .campaign-details-amount-list .single-amount-list::before {
          display: none; } }
    .campaign-details-amount-list .single-amount-list::after {
      content: '';
      top: 50%;
      right: -25px;
      width: 10px;
      height: 10px;
      margin-top: -4px;
      background-color: #e1e1e1; }
      @media (max-width: 380px) {
        .campaign-details-amount-list .single-amount-list::after {
          display: none; } }
    .campaign-details-amount-list .single-amount-list .caption {
      margin-bottom: 0.3125rem; }
      .campaign-details-amount-list .single-amount-list .caption i {
        font-size: 1.125rem; }
    .campaign-details-amount-list .single-amount-list .amount {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1; }

.donate-form {
  width: 450px; }
  @media (max-width: 575px) {
    .donate-form {
      flex-wrap: wrap;
      width: 100%; } }
  .donate-form .form--control {
    width: 280px;
    border-radius: 999px;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px;
    margin-right: 20px; }
    @media (max-width: 1199px) {
      .donate-form .form--control {
        width: 250px; } }
    @media (max-width: 575px) {
      .donate-form .form--control {
        width: 100%;
        margin-right: 0; } }
  .donate-form .btn {
    width: 150px;
    font-size: 14px;
    height: 55px;
    border-radius: 999px;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px; }
    @media (max-width: 575px) {
      .donate-form .btn {
        width: 100%;
        margin-top: 0.9375rem; } }

.campaign-details-content {
  margin-top: 2.5rem; }
  .campaign-details-content .custom--nav-tabs {
    background-color: #f7f7f7; }
    .campaign-details-content .custom--nav-tabs .nav-item .nav-link {
      background-color: #f7f7f7; }

.gallery-item:hover .gallery-item-icon {
  opacity: 1; }
  .gallery-item:hover .gallery-item-icon i {
    opacity: 1; }

.gallery-item .gallery-item-icon {
  background-color: rgba(26, 42, 74, 0.65);
  opacity: 0; }
  .gallery-item .gallery-item-icon i {
    font-size: 2rem;
    opacity: 0;
    transition-delay: 0.3s; }

/* campaign details section css end */
/* create campaign section css start */
.create-campaign-area {
  padding: 1.875rem;
  background-color: #f7f7f7; }
  @media (max-width: 575px) {
    .create-campaign-area {
      padding: 1.25rem; } }

/* create campaign section css end */
/* dashboard section css start */
.d-widget {
  padding: 0 1.25rem 1.25rem 1.25rem; }
  .d-widget__icon {
    width: 60px;
    height: 60px;
    margin-top: -21px; }
    .d-widget__icon i {
      font-size: 1.5rem; }
  .d-widget__content {
    margin-top: 1.25rem; }
    .d-widget__content .caption {
      color: #e1e1e1; }
  .d-widget__amount {
    font-family: "Poppins", sans-serif;
    font-size: 1.75rem;
    font-weight: 500; }
  .d-widget:not(.style--two) .d-widget__amount {
    font-size: 1.5rem; }
  .d-widget.style--two {
    padding: 1.25rem; }
    .d-widget.style--two::after {
      content: "\f061";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      top: 10px;
      right: 15px;
      color: rgba(255, 255, 255, 0.45);
      font-size: 1.25rem; }
    .d-widget.style--two:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); }
    .d-widget.style--two .d-widget__icon {
      margin-top: 0; }
    .d-widget.style--two .d-widget__content {
      margin-top: 0;
      width: calc(100% - 60px);
      padding-left: 1.25rem;
      text-align: left; }
      @media (max-width: 1399px) {
        .d-widget.style--two .d-widget__content .caption {
          font-size: 0.75rem; } }
    .d-widget.style--two .d-widget__amount {
      line-height: 1.3; }

/* dashboard section css end */
