/* campaign details section css start */
.campaign-details-top {
  padding: rem(30px);
  background-color: #f7f7f7;
  @extend %bs-10;
  .thumb {
    max-height: 650px;
    @extend %overflow-hidden;
    @extend %bs-8;
    img {
      @extend %w-100;
      @extend %h-100;
      @extend %obj-fit; } }
  .title {
    font-size: rem(24px);
    margin-top: rem(20px); }
  .progressbar {
    margin-top: rem(40px);
    height: 10px;
    background-color: #ebebeb;
    @include border-radius(999px);
    .bar {
      @include border-radius(999px); } } }

.campaign-details-amount-list {
  @extend %d-flex;
  @extend %align-center;
  margin: -10px -40px;
  .single-amount-list {
    padding: 10px 40px;
    @extend %position-relative;
    &:last-child {
      &::before, &::after {
        display: none; } }
    &::before {
      @extend %position-absolute;
      content: '';
      top: 50%;
      right: -20px;
      width: 40px;
      height: 2px;
      background-color: #e1e1e1;
      @include media(380px) {
        display: none; } }
    &::after {
      @extend %position-absolute;
      content: '';
      top: 50%;
      right: -25px;
      width: 10px;
      height: 10px;
      margin-top: -4px;
      background-color: #e1e1e1;
      @extend %bs-50;
      @include media(380px) {
        display: none; } }
    .caption {
      @extend %d-flex;
      @extend %align-center;
      margin-bottom: rem(5px);
      i {
        font-size: rem(18px); } }
    .amount {
      font-weight: 500;
      font-size: rem(20px);
      line-height: 1; } } }

.donate-form {
  @extend %d-inline-flex;
  @extend %align-center;
  width: 450px;
  @include media(575px) {
    flex-wrap: wrap;
    width: 100%; }
  .form--control {
    width: 280px;
    @include border-radius(999px);
    margin-right: 20px;
    @include media(1199px) {
      width: 250px; }
    @include media(575px) {
      width: 100%;
      margin-right: 0; } }
  .btn {
    width: 150px;
    font-size: 14px;
    height: 55px;
    @include border-radius(999px);
    @include media(575px) {
      width: 100%;
      margin-top: rem(15px); } } }

.campaign-details-content {
  margin-top: rem(40px);
  .custom--nav-tabs {
    background-color: #f7f7f7;
    .nav-item {
      .nav-link {
        background-color: #f7f7f7;
        &.active {
          @extend %base-color;
          @extend %text-h; } } } } }

.gallery-item {
  @extend %position-relative;
  &:hover {
    .gallery-item-icon {
      opacity: 1;
      i {
        opacity: 1; } } }
  .gallery-item-icon {
    @extend %position-absolute;
    @extend %w-100;
    @extend %h-100;
    background-color: rgba($base-color-two, 0.65);
    @extend %d-flex;
    @extend %align-center;
    @extend %justify-center;
    @extend %text-white;
    opacity: 0;
    @extend %transition;
    i {
      font-size: rem(32px);
      opacity: 0;
      @extend %transition;
      transition-delay: 0.3s; } } }


/* campaign details section css end */
