
/* blog details section css start */
.blog-details-thumb {
  max-height: 450px;
  @extend %overflow-hidden;
  @extend %bs-5;
  img {
    @extend %w-100;
    @extend %h-100;
    @extend %obj-fit; } }

.blog-details-title {
  font-size: rem(32px);
  @include media(991px) {
    font-size: rem(28px); }
  @include media(575px) {
    font-size: rem(24px); } }

.post-share {
  padding: rem(20px) 0;
  border-top: 1px solid rgba(#000, 0.15);
  border-bottom: 1px solid rgba(#000, 0.15);
  margin: rem(-3px) rem(-5px);
  li {
    padding: rem(3px) rem(5px);
    a {
      width: rem(35px);
      height: rem(35px);
      border: 1px solid rgba(#000, 0.15);
      @include border-radius(50%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: $para-color;
      &:hover {
        @extend %base-color;
        @extend %text-white;
        border-color: $base-color; } } } }

.blog-sidebar {
  padding: rem(20px);
  .title {
    position: relative;
    padding-bottom: rem(5px);
    margin-bottom: rem(30px);
    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: rem(35px);
      height: rem(2px);
      @extend %base-color; } } }

.s-post {
  padding: rem(15px) 0;
  border-bottom: 1px solid rgba(#fff, 0.15);
  &:first-child {
    padding-top: 0; }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none !important; }
  &__thumb {
    width: rem(80px);
    height: rem(70px);
    img {
      width: 100%;
      height: 100%;
      @extend %obj-fit; } }
  &__content {
    width: calc(100% - 80px);
    padding-left: rem(20px); }
  &__title {
    font-size: rem(16px);
    a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; } } }
/* blog details section css end */
