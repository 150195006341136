@import "./_reset";
/* global css strat */
@import "./_color";
@import "./_bg";

.bg_img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.bg-attachment-fixed {
  background-attachment: fixed; }

.section--bg {
  background-color: #F8F9FA !important; }

.section--bg {
  background-color: #F8F9FA !important; }

.section--bg2 {
  background-color: $base-color-two; }

.bg-base--light {
  background-color: rgba($base-color, 0.05) !important; }

.top--border-base {
  border-top: 2px solid $base-color; }

.alert--dark {
  background-color: #505C66;
  @extend %text-white; }

.info-message-alert {
  @extend %d-flex;
  @extend %align-center;
  .icon {
    width: 40px;
    font-size: rem(20px); }
  .content {
    width: calc(100% - 40px); } }

.slick-arrow {
  cursor: pointer; }
.z-index-2 {
  z-index: 2; }
.main-wrapper {
  position: relative; }
.section-header {
  margin-bottom: rem(45px);
  margin-top: -8px;
  @include media(767px) {
    margin-bottom: rem(35px); }
  @include media(575px) {
    margin-bottom: rem(30px); }
  p {
    @include media(767px) {
      font-size: rem(15px); } } }
.section-title {
  font-size: rem(48px);
  font-weight: 700;
  @include media(1199px) {
    font-size: rem(36px); }
  @include media(767px) {
    font-size: rem(28px); }
  @include media(420px) {
    font-size: rem(24px); } }

.section-top-title {
  font-weight: 500;
  @extend %text-base;
  letter-spacing: 3px; }

a.text-white {
  &:hover {
    color: $base-color !important; } }
.text--link {
  text-decoration: underline;
  &:hover {
    text-decoration: underline; } }
.has--link {
  position: relative;
  .item--link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; } }

.object-fit--cover {
  @include object-fit; }

.dark--overlay {
  @extend %position-relative;
  @extend %z-index-p;
  &::after {
    @extend %position-absolute;
    content: '';
    top: 0;
    left: 0;
    @extend %w-100;
    @extend %h-100;
    @extend %base-color-two;
    opacity: 0.75;
    @extend %z-index-c; } }

/* back to top css start */
.progress-wrap {
  position: fixed;
  right: 10px;
  bottom: 10px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba($base-color, 0.2);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background: #fff;

  &.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }

  &::after {
    position: absolute;
    content: "\f30c";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    text-align: center;
    line-height: 46px;
    font-size: 20px;
    color: $base-color;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; }
  svg {
    path {
      fill: none; }
    &.progress-circle path {
      stroke: $base-color;
      stroke-width: 4;
      box-sizing: border-box;
      -webkit-transition: all 200ms linear;
      transition: all 200ms linear; } } }
/* back to top css end */


.preloader {
  position: fixed;
  inset: 0;
  @extend %base-color-two;
  z-index: 9999999;
  @extend %d-flex;
  @extend %justify-center;
  @extend %align-center;
  &__circle {
    @extend %position-relative;
    @extend %d-inline-flex;
    @extend %justify-center;
    @extend %align-center;
    width: 75px;
    height: 75px;
    @extend %bs-50;
    &::after {
      @extend %position-absolute;
      content: '';
      @extend %w-100;
      @extend %h-100;
      @extend %bs-50;
      border: 3px solid rgba(#fff, 0.15);
      border-right: none;
      border-bottom: none;
      animation: spin 1.5s infinite linear; }
    i {
      font-size: rem(34px);
      @extend %text-base; } } }
/* global css end */
