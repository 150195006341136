.user-profile {
  padding: rem(20px);
  background-color: #fff;
  @extend %bs-5;
  @include media(1199px) {
    padding: rem(20px); } }

.profile-thumb {
  position: relative;
  width: rem(200px);
  height: rem(200px);
  @include border-radius(15px);
  display: inline-flex;
  .profilePicPreview {
    width: rem(200px);
    height: rem(200px);
    @include border-radius(15px);
    display: block;
    border: 2px solid #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    background-size: cover;
    background-position: top; }
  .profilePicUpload {
    font-size: 0;
    opacity: 0; }
  .avatar-edit {
    position: absolute;
    right: 0;
    bottom: -10px;
    input {
      width: 0; }
    label {
      font-size: rem(12px);
      padding: rem(1px) rem(15px);
      @extend %base-color;
      @include border-radius(999px);
      @extend %text-white;
      cursor: pointer; } } }

.profile-info-list {
  li {
    padding: rem(14px) 0;
    @extend %d-flex;
    border-bottom: 1px dashed darken($border-color, 5%);
    font-size: rem(15px);
    @include media(575px) {
      font-size: rem(14px); }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0; }
    .caption {
      width: 40%;
      font-weight: 600;
      display: flex;
      align-items: center;
      i {
        font-size: rem(20px);
        width: 25px; } }
    .details {
      width: 60%;
      padding-left: 10px; } } }
