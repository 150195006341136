/* hero section css start */
.hero {
  padding-top: 360px;
  padding-bottom: 280px;
  @extend %position-relative;
  @extend %z-index-p;
  @extend %overflow-hidden;
  @include media(1399px) {
    padding-top: 275px;
    padding-bottom: 185px; }
  @include media(1199px) {
    padding-top: 230px; }
  @include media(991px) {
    padding-top: 200px;
    padding-bottom: 135px; }
  @include media(767px) {
    padding-top: 175px;
    padding-bottom: 115px; }
  &::before {
    @extend %position-absolute;
    content: '';
    top: 0;
    left: 0;
    @extend %w-100;
    @extend %h-100;
    @extend %base-color-two;
    opacity: 0.65;
    z-index: -1; }
  &__top,
  &__left,
  &__right {
    @extend %position-absolute;
    z-index: -1; }
  &__top {
    top: -150px;
    left: -80px;
    width: 400px;
    @include media(991px) {
      max-width: 300px; } }
  &__left {
    bottom: -195px;
    left: -185px;
    @include media(991px) {
      max-width: 400px; }
    @include media(767px) {
      max-width: 300px; } }
  &__right {
    bottom: -125px;
    right: -135px;
    @include media(991px) {
      max-width: 550px; }
    @include media(767px) {
      max-width: 450px; } }
  &__top-title {
    font-weight: 500;
    @extend %text-base;
    font-size: rem(18px);
    letter-spacing: 3px;
    @include media(575px) {
      font-size: rem(16px); } }
  &__title {
    font-family: $special-font;
    font-size: rem(72px);
    @extend %text-white;
    @include media(1399px) {
      font-size: rem(66px); }
    @include media(1199px) {
      font-size: rem(56px); }
    @include media(767px) {
      font-size: rem(42px); }
    @include media(575px) {
      font-size: rem(32px); } }
  .hero-img-slider {
    @extend %position-absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    .slick-list {
      @extend %h-100; }
    .slick-track {
      @extend %h-100; }
    .slider-track {
      @extend %h-100;
      -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1); }
    .single-slide {
      height: 100%;
      position: relative;
      z-index: 1;
      img {
        @extend %w-100;
        @extend %h-100;
        @extend %obj-fit;
        -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        -webkit-transform: scale(1.2);
        transform: scale(1.2); }
      &.slick-active img {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation: fadeAnimation 8s 1 ease-in-out forwards;
        animation: fadeAnimation 8s 1 ease-in-out forwards; } } } }

@keyframes fadeAnimation {
  from {
    -webkit-transform: scale(1) translate(0px); }
  to {
    -webkit-transform: scale(1.2) translate(0px); } }
@-webkit-keyframes fadeAnimation {
  from {
    -webkit-transform: scale(1) translate(0px); }
  to {
    -webkit-transform: scale(1.2) translate(0px); } }
/* hero section css end */


/* inner hero section css start */
.inner-hero {
  padding-top: 150px;
  padding-bottom: 70px;
  @extend %position-relative;
  @extend %z-index-p;
  &::before {
    @extend %position-absolute;
    content: '';
    top: 0;
    left: 0;
    @extend %w-100;
    @extend %h-100;
    @extend %base-color-two;
    opacity: 0.75;
    @extend %z-index-c; } }

.page-breadcrumb {
  @include d-flex;
  margin-top: 15px;
  li {
    color: rgba(#ffffff, 0.8);
    text-transform: capitalize;
    &::after {
      content: '-';
      color: #ffffff;
      margin: 0 5px; }
    &:first-child {
      &::before {
        content: "\f015";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: $base-color;
        margin-right: 6px; } }
    &:last-child {
      &::after {
        display: none; } }
    a {
      color: #ffffff;
      text-transform: capitalize;
      &:hover {
        color: $base-color; } } } }
/* inner hero section css end */
