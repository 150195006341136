$heading-color: #082032;
$para-color: #666666;

$base-color: #f1b85c;
$base-color-two: #1a2a4a;
$base-color-three: #475443;

$section-bg: #F0F3F5;

$body-bg: #fff;

// border-color
// $border-color: #a7a7a7
$border-color: #dadce0;

// color variable for Bootstrap
$primary: #7367f0;
$secondary: #868e96;
$success: #28c76f;
$danger: #ea5455;
$warning: #ff9f43;
$info: #1e9ff2;
$dark: #082032;
$light: #bcc7da;
$muted: #a0a0a0;
