/* dashboard section css start */
.d-widget {
  padding: 0 rem(20px) rem(20px) rem(20px);
  @extend %base-color-two;
  @extend %bs-8;
  @extend %text-center;
  @extend %h-100;
  &__icon {
    width: 60px;
    height: 60px;
    @extend %base-color;
    @extend %text-h;
    @extend %bs-50;
    @extend %d-inline-flex;
    @extend %align-center;
    @extend %justify-center;
    margin-top: -21px;
    i {
      font-size: rem(24px); } }
  &__content {
    margin-top: rem(20px);
    .caption {
      color: #e1e1e1; } }
  &__amount {
    font-family: $para-font;
    font-size: rem(28px);
    font-weight: 500;
    @extend %text-white; }
  &:not(.style--two) {
    .d-widget__amount {
      font-size: rem(24px); } }
  &.style--two {
    padding: rem(20px);
    @extend %d-flex;
    @extend %align-center;
    @extend %transition;
    @extend %position-relative;
    &::after {
      @extend %position-absolute;
      content: "\f061";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      top: 10px;
      right: 15px;
      color: rgba(#fff, 0.45);
      font-size: rem(20px); }
    &:hover {
      @extend %trans-y;
      box-shadow: 0 5px 15px rgba(#000, 0.15); }
    .d-widget__icon {
      margin-top: 0; }
    .d-widget__content {
      margin-top: 0;
      width: calc(100% - 60px);
      padding-left: rem(20px);
      text-align: left;
      .caption {
        @include media(1399px) {
          font-size: rem(12px); } } }
    .d-widget__amount {
      line-height: 1.3; } } }
/* dashboard section css end */
