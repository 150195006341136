/* support section css start */
.custom--file-upload {
  position: relative;
  line-height: rem(32px);
  font-size: rem(15px);
  &::before {
    position: absolute;
    content: 'Choose File';
    @extend %text-white;
    top: -1px;
    left: -1px;
    width: 115px;
    height: calc(100% + 2px);
    background-color: $base-color;
    padding: rem(10px) rem(20px);
    line-height: 24px; } }
.single-reply {
  @include d-flex;
  align-items: center;
  padding: rem(30px);
  background-color: #cacaca0f;
  @extend %bs-5;
  @include media(575px) {
    padding: rem(20px); }
  .left {
    width: 20%;
    @include media(767px) {
      width: 100%; } }
  .right {
    width: 80%;
    border-left: 1px solid rgba(#000, 0.15);
    padding-left: rem(20px);
    @include media(767px) {
      width: 100%;
      padding-left: 0;
      border-left: none;
      margin-top: rem(20px); } } }
.single-reply + .single-reply {
  margin-top: 20px; }

.support-upload-field {
  @extend %d-flex;
  &__left {
    width: calc(100% - 80px);
    padding-right: 15px;
    @include media(480px) {
      width: 100%;
      padding-right: 0; } }
  &__right {
    width: 80px;
    padding-top: 33px;
    @include media(480px) {
      width: 100%;
      padding-top: 0;
      order: -1;
      text-align: right;
      margin-bottom: -25px;
      position: relative;
      z-index: 1; }
    button.btn {
      padding: rem(10px) rem(25px);
      @extend %w-100;
      @include media(480px) {
        padding: 3px 8px; } } } }

.single-file-upload {
  @extend %d-flex;
  .form-control {
    width: calc(100% - 60px);
    @include border-radius(5px 0 0 5px);
    border-right: 0;
    @include media(380px) {
      width: calc(100% - 30px); } }
  .btn {
    width: 60px;
    padding: rem(10px);
    @include border-radius(0 5px 5px 0);
    @include media(380px) {
      padding: 5px;
      width: 30px; } } }
/* support section css end */
