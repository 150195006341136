/* modal css start */
.modal {
  z-index: 999999; }
.modal-open {
  overflow: hidden;
  overflow-y: auto;
  padding-right: 0 !important; }

.btn-close {
  width: 18px;
  height: 18px;
  background-color: $danger;
  opacity: 1;
  @extend %d-flex;
  @extend %align-center;
  @extend %justify-center;
  &:hover {
    opacity: 1; }
  &:focus {
    box-shadow: none; } }

.modal {
  &.fade {
    .modal-dialog {
      transform: scale(0.85, 0.85) translate(0); } }
  &.show {
    .modal-dialog {
      transform: scale(1, 1) translate(0); } } }
/* modal css end */
