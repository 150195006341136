/* authentication section css start */
.qr-code-wrapper {
  padding: rem(20px);
  @extend %bg-white; }
.qr-code {
  padding: rem(10px) rem(10px);
  background-color: #cecece73;
  @extend %bs-5; }
.qr-code-copy-form {
  @extend %d-flex;
  input[type="text"] {
    background-color: transparent;
    border: none;
    font-size: rem(20px);
    width: calc(100% - 75px);
    height: 40px;
    @include media(440px) {
      font-size: rem(16px); }
    @include media(360px) {
      font-size: rem(14px); } }
  .text-copy-btn {
    width: 75px;
    background-color: $base-color;
    border: none;
    @extend %bs-5; } }
.qr-code-form {
  @extend %position-relative;
  .form--control {
    height: rem(65px);
    padding-right: rem(95px); }
  &__btn {
    @extend %position-absolute;
    top: 10px;
    right: 10px;
    width: 75px;
    height: calc(100% - 20px); } }
/* authentication section css end */
