/* blog section css start */
.post-item {
  &:hover {
    .post-item__thumb {
      @extend %trans-y; } }
  &__thumb {
    @extend %bs-8;
    @extend %overflow-hidden;
    height: 275px;
    box-shadow: 0 8px 15px rgba($base-color-two, 0.15);
    @extend %transition;
    @include media(1199px) {
      height: 220px; }
    img {
      @extend %w-100;
      @extend %h-100;
      @extend %obj-fit; } }
  &__content {
    margin-top: 25px;
    .title {
      font-size: 20px;
      a {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } } } }
/* blog section css end */
