/* event-details-sidebar css start */
.event-details-sidebar {
  position: sticky;
  top: 30px; }

.event-details-widget {
  padding: rem(15px);
  @extend %bs-5;
  @extend %bg-white;
  box-shadow: 0 3px 8px rgba(#000, 0.05); }

.widget-organizer {
  @extend %d-flex;
  @extend %align-center;
  .thumb {
    width: 60px;
    height: 60px;
    @extend %overflow-hidden;
    @extend %bs-5;
    img {
      @extend %w-100;
      @extend %h-100;
      @extend %obj-fit; } }
  .content {
    width: calc(100% - 60px);
    padding-left: rem(15px); }
  .organizer-name {
    font-size: rem(18px); } }

.event-wiget-info {
  li {
    @extend %d-flex;
    padding: rem(10px) 0;
    border-bottom: 1px dashed lighten($border-color, 20%);
    i {
      width: 40px;
      font-size: 28px; }
    .content {
      width: calc(100% - 40px);
      padding-left: rem(10px);
      color: $heading-color;
      margin-top: -3px; } } }

.social-share {
  @extend %d-flex;
  @extend %align-center;
  margin: rem(-2px) rem(-4px);
  li {
    padding: rem(2px) rem(4px); }
  a {
    width: 35px;
    height: 35px;
    @extend %base-color-two;
    @extend %text-white;
    @include border-radius(3px);
    @extend %d-flex;
    @extend %align-center;
    @extend %justify-center; } }


.action-sidebar {
  // background-color: #fff
  @extend %transition;
  @extend %bs-8;
  @include media(991px) {
    padding: 30px;
    min-height: 100vh;
    width: 350px;
    position: fixed;
    top: 0;
    left: -105%;
    z-index: 99999;
    max-width: 100%;
    box-shadow: 5px 5px 10px rgba(#000, 0.15);
    max-height: calc(100vh - 75px);
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: darkgrey #e7e7e7;
    &::-webkit-scrollbar {
      width: 5px; }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      @include border-radius(999px); }
    &.active {
      left: 0; } }
  @include media(575px) {
    padding: 20px 15px; } }
.action-widget {
  padding: 20px 0;
  border-bottom: 1px solid lighten($border-color , 3%);
  &:first-child {
    padding-top: 0; }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  &__title {
    font-size: rem(15px);
    color: $heading-color;
    // padding-bottom: rem(5px)
    // border-bottom: 1px solid lighten($border-color, 8%)
    position: relative;
    padding-right: 20px;
    cursor: pointer;
    &::after {
      position: absolute;
      content: "\f107";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      top: 0;
      right: 0;
      font-size: rem(14px); } }
  &__body {
    margin-top: rem(15px);
    @extend %position-relative;
    &.scroll--active {
      max-height: 200px;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: darkgrey #e7e7e7;
      &::-webkit-scrollbar {
        width: 5px; }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        @include border-radius(999px); } } } }
.search-form-inline {
  position: relative;
  input {
    padding-left: 10px;
    padding-right: 30px; }
  &__btn {
    position: absolute;
    top: 50%;
    right: 5px;
    @include transform(translateY(-50%));
    background-color: transparent; } }
.action-sidebar-close {
  width: 30px;
  height: 30px;
  background-color: $base-color;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  @include media(991px) {
    display: inline-block; } }
.action-sidebar-open {
  padding: rem(8px) rem(25px);
  background-color: $base-color;
  color: #fff;
  align-items: center;
  justify-content: center;
  @include border-radius(3px);
  display: none;
  @include media(991px) {
    display: flex; }
  i {
    font-size: rem(22px);
    margin-right: 5px; } }

#filter-domain-add-list {
  margin-top: 5px;
  a {
    margin: 3px;
    padding: rem(3px) rem(10px);
    @extend %text-p;
    border: 1px solid $border-color;
    @include border-radius(5px);
    font-size: rem(13px); } }

.price-slider {
  @extend %d-flex;
  flex-direction: column;
  .slider-range, .slider-range-two {
    order: 2;
    border: none !important;
    height: 5px;
    background-color: #cacaca;
    margin: 0 8px; }
  .amount__field {
    @extend %d-flex;
    justify-content: space-between;
    @extend %align-center;
    margin-bottom: 4px;
    label {
      margin-bottom: 0;
      font-weight: 400; } }
  .range-amount {
    text-align: right;
    padding: 0;
    @extend %text-p;
    font-weight: 600;
    font-size: rem(14px);
    border: none;
    width: 150px; }
  .ui-widget-header {
    background: #ececec; }
  .ui-slider .ui-slider-handle {
    background: #fff;
    @extend %bs-50;
    width: 15px;
    height: 15px;
    top: -6px;
    border: 1px solid $base-color;
    cursor: pointer; } }
/* event-details-sidebar css end */
