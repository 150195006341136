/* card css start */
.custom--card {
  box-shadow: 0 3px 15px #8898aa26;
  @extend %bg-white;
  // border: 1px solid $border-color
  @extend %bs-8;
  overflow: hidden;
  .card-header {
    background-color: #fff;
    padding: rem(15px) rem(20px);
    border-color: $border-color; }
  .card-body {
    padding: rem(20px);
    @include media(575px) {
      padding: rem(15px); } } }
/* card css end */
