/* contact section css start */
.contact-section {
  @extend %position-relative;
  &::after {
    @extend %position-absolute; } }

.contact-list {
  margin-top: 33px;
  li + li {
    margin-top: rem(20px); }
  li {
    @extend %d-flex;
    @extend %align-center;
    .icon {
      width: 40px;
      i {
        font-size: rem(28px); } }
    .content {
      width: calc(100% - 40px);
      a {
        @extend %text-p; } } } }

.social-links {
  @extend %d-flex;
  li {
    a {
      @extend %text-p;
      @extend %bg-white;
      border: 1px solid $border-color;
      &:hover {
        @extend %text-h; } } } }

.map-area {
  margin-top: rem(30px);
  iframe {
    width: 100%;
    height: 247px; } }
/* contact section css end */
