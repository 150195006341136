/* campaign section css start */
.campaign-section-layer {
  @extend %position-absolute;
  top: 0;
  left: 0;
  @extend %w-100;
  height: 450px;
  @extend %z-index-c;
  &::before {
    @extend %position-absolute;
    content: '';
    top: 0;
    left: 0;
    @extend %w-100;
    @extend %h-100;
    @extend %base-color-two;
    opacity: 0.7;
    @extend %z-index-c; } }

.campaign-item {
  @extend %bg-white;
  box-shadow: 0 10px 18px rgba(#000, 0.05);
  @extend %transition;
  &:hover {
    box-shadow: 0 5px 15px rgba(#000, 0.05);
    @extend %trans-y; }
  .thumb {
    @extend %position-relative;
    height: 275px;
    @extend %overflow-hidden;
    img {
      @extend %w-100;
      @extend %h-100;
      @extend %obj-fit; }
    .progressbar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; } }
  &__tag {
    padding: rem(2px) rem(12px);
    @extend %base-color;
    @extend %text-h;
    font-size: rem(13px); }
  .content {
    .inner {
      padding: rem(25px); }
    .title {
      font-size: rem(20px);
      a {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } } }
  .progressbar {
    margin-top: rem(40px); }
  .bottom {
    padding: rem(15px) rem(25px);
    border-top: 1px solid $border-color;
    @include media(575px) {
      padding: rem(10px) rem(15px); }
    @extend %d-flex;
    .single-amount {
      width: calc(100% / 3);
      @extend %text-center;
      @extend %position-relative;
      font-weight: 500;
      padding: 0 rem(10px);
      &::after {
        @extend %position-absolute;
        content: '';
        top: 50%;
        right: 0;
        width: 1px;
        height: calc(100% - 10px);
        background-color: $border-color;
        @include transform(translateY(-50%)); }
      &:last-child {
        &::after {
          display: none; } }
      .caption {
        @extend %text-h;
        font-size: rem(14px); }
      .amount {
        @extend %text-base;
        word-break: break-all; } } } }

.progressbar {
  @extend %position-relative;
  @extend %w-100;
  height: 6px;
  background-color: #eeeeee;
  // +border-radius(999px)
  .bar {
    @extend %position-absolute;
    width: 0px;
    @extend %h-100;
    top: 0;
    left: 0;
    @extend %base-color;
    @extend %overflow-hidden;
 }    // +border-radius(999px)
  .label {
    @extend %position-absolute;
    top: -30px;
    left: 0;
    width: 30px;
    height: 20px;
    @extend %d-flex;
    @extend %align-center;
    @extend %justify-center;
    @extend %base-color;
    @extend %text-h;
    font-size: rem(12px);
    @include transform(translateX(-15px));
    &:before {
      @extend %position-absolute;
      content: '';
      bottom: -10px;
      left: 50%;
      @include transform(translateX(-50%));
      width: 10px;
      height: 10px;
      border-top: 6px solid $base-color;
      border-bottom: 5px solid transparent;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent; } } }

.campaign-slider {
  .slick-list {
    margin: -30px -15px; }
  .single-slide {
    padding: 30px 15px; }
  .slick-arrow {
    @extend %position-absolute;
    top: 50%;
    @extend %z-index-p;
    width: 50px;
    height: 50px;
    @extend %d-flex;
    @extend %align-center;
    @extend %justify-center;
    font-size: rem(22px);
    @extend %bg-white;
    @extend %text-h;
    @extend %bs-50;
    border: 2px solid $border-color;
    margin-top: -25px;
    @include media(1550px) {
      top: 30%; }
    &.prev {
      left: -85px;
      @include media(1550px) {
        left: 5px; } }
    &.next {
      right: -85px;
      @include media(1550px) {
        right: 5px; } } } }

/* campaign section css end */
