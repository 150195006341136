.custom--nav-tabs {
  border-bottom: none;
  .nav-item {
    .nav-link {
      @extend %w-100;
      background-color: #fff;
      border: none;
      @include border-radius(0px);
      padding: rem(12px) rem(25px);
      @extend %text-p;
      font-weight: 500;
      &.active {
        background-color: $base-color;
        color: #fff; } } } }
