/* volunteer section css start */
.volunteer-section {
  @extend %d-flex;
  justify-content: flex-end;
  @extend %overflow-hidden;
  @extend %align-center;
  @include media(1199px) {
    justify-content: center; }
  .left {
    width: 450px;
    padding-left: rem(20px);
    @include media(1199px) {
      width: 100%;
      max-width: 700px;
      padding: 0 rem(20px);
      text-align: center;
      margin-bottom: 35px; } }
  .right {
    width: 1075px;
    padding-left: rem(50px);
    @include media(1550px) {
      width: calc(100% - 450px); }
    @include media(1199px) {
      width: 100%;
      padding: 0 rem(40px); } } }

.volunteer-item {
  @extend %bg-white;
  .thumb {
    height: 220px;
    @extend %overflow-hidden;
    @extend %position-relative;
    img {
      @extend %w-100;
      @extend %h-100;
      @extend %obj-fit; } }
  .content {
    padding: rem(20px);
    border: 1px solid $border-color;
    border-top: none;
    @extend %text-center;
    .name {
      font-size: rem(22px); }
    .caption {
      font-size: rem(14px); } } }
.volunteer-slider {
  .slick-list {
    margin: 0 -7px; }
  .single-slide {
    padding: 0 7px; }
  .slick-arrow {
    @extend %position-absolute;
    top: 50%;
    left: -22px;
    width: 45px;
    height: 40px;
    @extend %d-flex;
    @extend %align-center;
    @extend %justify-center;
    @extend %bg-white;
    @extend %text-h;
    font-size: rem(18px);
    @extend %z-index-p;
    box-shadow: 0 03px rgba(#000, 0.1);
    @include transform(translateY(-50%));
    &.prev {
      margin-top: -60px;
      @include media(1199px) {
        margin-top: 0;
        left: -20px; } }
    &.next {
      margin-bottom: -60px;
      @include media(1199px) {
        margin-top: 0;
        left: auto;
        right: -20px; } } } }
/* volunteer section css end */
