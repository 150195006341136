/* about section css start */
.about-thumb-area {
  @extend %position-relative;
  padding-right: 70px;
  padding-bottom: 50px; }

.about-thumb {
  img {
    @extend %w-100; } }

.about-thumb2 {
  @extend %position-absolute;
  width: 250px;
  height: 165px;
  @extend %overflow-hidden;
  bottom: 0;
  right: 0;
  border: 5px solid #fff;
  img {
    @extend %obj-fit; }
  .video-btn {
    @extend %position-absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    width: 65px;
    height: 65px;
    @extend %base-color;
    @extend %text-h;
    font-size: rem(28px);
    @extend %d-flex;
    @extend %align-center;
    @extend %justify-center;
    @extend %bs-50;
    @extend %z-index-p;
    @extend %transition;
    &:hover {
      @extend %base-color-two;
      @extend %text-white;
      &::after {
        @extend %base-color-two; } }
    &::after {
      @extend %position-absolute;
      content: '';
      top: -5px;
      left: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      @extend %bs-50;
      @extend %base-color;
      opacity: 0.45;
      @extend %z-index-c;
      @extend %transition; } } }

.founder {
  @extend %d-flex;
  @extend %align-center;
  .thumb {
    width: 70px;
    height: 65px;
    @extend %overflow-hidden;
    img {
      @extend %obj-fit; } }
  .content {
    padding-left: rem(15px);
    .name {
      font-size: rem(20px); } } }
/* about section css end */
